/* You can add global styles to this file, and also import other style files */
@import './assets/scss/onboarding';
@import './assets/scss/fonts';
@import './assets/scss/authCommon';
@import './assets/scss/cursorstyling';
@import './assets/scss/modules';
@import './assets/scss/dialog';
@import './assets/scss/navbar';
@import './assets/scss/tooltip';
@import './assets/scss/superAdmin';
@import './assets/scss/variables';
@import './assets/scss/preview';
@import './assets/scss/previewTrs';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/scss/pagination';

:root {
  /* FONTS */
  --font-family-sf-pro-display: 'SF Pro Display';
  --font-family-inter: 'Inter';

  /* SIZES */
  --sidebar-text-size: 14px;
  --heading-text-size: 26px;
  --modal-header-text-size: 1.125rem;

  --color-primary: #6365ef;
  --color-button-primary: #6365ef;
  --color-button-secondary: #d0d5dd;
  --color-active: #2D9563;
  --color-light-primary: #707070;
  --color-extra-light-primary: #f0f0fe;
  --color-white: #ffffff;
  --color-light-white: #f6f7ff;
  --color-bg-min-sidebar: #f6f7ff;
  --color-dark: #303030;
  --color-light-dark: #303030;
  --color-gray: #949494;
  --color-light-gray: #e9eaf0;
  --color-action-gray: #d9d9d978;
  --color-input-border: #69696980;
  --color-extra-light-gray: #f3f5f7;
  --color-dashed-gray: #6366684d;
  --color-danger: #d92d20;
  --color-bg-selected: #f7f7f7;
  --color-browse-file-bg: #e3e3e3;
  --color-input-bg: #0000000f;
  --color-date-text: #8b8b8b;
  --color-graph-axis: rgba(0, 0, 0, 0.08);
  --revenue-graph-color-trs: rgb(95, 206, 246);
  --revenue-graph-color-web: rgb(241, 191, 98);
  --revenue-graph-color-mobile: rgb(99, 101, 239);
  --revenue-graph-shadow-color-trs: #6365EF10;
  --revenue-graph-shadow-color-web: #6365EF10;
  --revenue-graph-shadow-color-mobile: #6365EF10;

  //static colors for popups
  --popup-static-color-dark: #000000;
  --popup-static-color-light-dark: #475467;
  --popup-static-color-button-secondary: #FFFFFF;
  --popup-static-color-button-secondary-border: #D0D5DD;


  @media screen and (max-width: 1679px) {
   --heading-text-size: 24px;
  }

  /* SVG Color Change */
  .revenue-icon {
    circle {
      fill: var(--color-primary);
    }
  }

  .icon {

    path,
    circle {
      fill: var(--color-dark);
    }
  }

  .info-default {
    path {
      fill: var(--info-button-bg-color);
      opacity: 0.55;
    }
  }

  .user-dashboard-table-container {
    box-shadow: 1px 5px 11px 0px color-mix(in srgb, var(--color-light-primary) 10%, transparent), 6px 19px 19px 0px color-mix(in srgb, var(--color-light-primary) 9%, transparent), 13px 42px 26px 0px color-mix(in srgb, var(--color-light-primary) 6.5%, transparent), 23px 74px 31px 0px color-mix(in srgb, var(--color-light-primary) 3%, transparent), 35px 116px 34px 0px color-mix(in srgb, var(--color-light-primary) 0%, transparent);
    background: var(--color-white);
  }

  .search-form .form-control,
  .nav-item.dropdown .dropdown-toggle,
  .switch,
  .btn-dashboard-head {
    box-shadow: none;
  }

  input[type='date'] {
    &::-webkit-calendar-picker-indicator {
      background: url(/assets/icons/down-caret-black.svg) no-repeat;
    }
  }

  .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: var(--color-date-text);
  }

  .loyalty-point-program-svg {
    path {
      fill: #000000 !important;
      fill-opacity: 0.7 !important;
    }
  }
}

.darkMode {
  --color-light-primary: #00000040;
  --color-light-gray: #24272c;
  --color-action-gray: #181b21;
  --color-extra-light-gray: #0d0d0d;
  --color-bg-min-sidebar: #0d0e0e;
  --color-bg-selected: #1e1e1e;
  --color-browse-file-bg: #42474b;
  --color-input-bg: #ffffff0f;
  --color-date-text: #2c2c2c;
  --color-graph-axis: rgba(255, 255, 255, 0.08);

  //static colors for popups
  --popup-static-color-dark: #FFFFFF;
  --popup-static-color-light-dark: #8B8B8B;
  --popup-static-color-button-secondary: #1E1E1E;
  --popup-static-color-button-secondary-border: #1E1E1E;


  .info-default {
    path {
      fill: var(--info-button-bg-color);
      opacity: 1;
    }
  }

  .user-management {
    .copy-div:hover {
      path {
        fill: #ffffff;
      }
    }
  }

  .add-user-icon {
    .rect1 {
      fill: #33a85b;
    }

    .rect2 {
      stroke: #1f6644;
    }
  }

  .confirmation-icon {
    .rect1 {
      fill: #ffd6d3;
    }

    .rect2 {
      stroke: #ff6d63;
    }
  }

  .down-arrow {
    path {
      fill: #ffffff;
    }
  }

  .container-ng-select {
    .default path {
      stroke: #ffffff;
    }
  }

  .user-dashboard-table-container {
    color: var(--color-dark);
  }

  .search-form .form-control,
  .nav-item.dropdown .dropdown-toggle,
  .switch,
  .btn-dashboard-head,
  .user-dashboard-table-container {
    box-shadow: var(--box-shadow-size-color);
  }

  .es-select {
    background-image: url(assets/icons/select-arrow-dark.svg) !important;
  }

  .ng-tooltip {
    &-light {
      background: #0d0c0f !important;
      color: #ffffff !important;
    }
  }

  input[type='date'] {
    &::-webkit-calendar-picker-indicator {
      background: url(/assets/icons/down-caret-white.svg) no-repeat;
    }
  }

  .mat-datepicker-content .mat-calendar {
    width: 296px;
    height: 354px;
    background: var(--color-white);
    color: var(--color-dark);
  }

  .mat-calendar-table-header,
  .mat-calendar-body-label,
  .mat-calendar-body-cell-content.mat-focus-indicator,
  .mat-calendar-previous-button::after,
  .mat-calendar-next-button::after,
  .mat-date-range-input-separator,
  .mat-datepicker-toggle-default-icon {
    color: var(--color-dark);
  }

  .mat-calendar-arrow polygon {
    fill: var(--color-dark);
  }

  .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: var(--color-date-text);
  }

  .showMenu .sub-menu {
    border-left: 2px solid rgba(255, 255, 255, 0.14) !important;
  }

  .loyalty-point-program-svg {
    path {
      fill: #ffffff !important;
      fill-opacity: 1 !important;
    }
  }
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  letter-spacing: 0.5px;

  .modal-lg {
    --bs-modal-width: 591px; // for subscription and plan add/edit modals
  }

  .modal-md {
    --bs-modal-width: 419px; // for subscription info modals
  }

  .modal-reports-info {
    --bs-modal-width: 412px; // for reports email send successfully info modals
  }

  .modal-sm {
    --bs-modal-width: 400px; // for customer add/edit, plan info, subscriber info modals
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #f0f0fe;
  border-radius: 16px;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

::-webkit-date-edit-year-field:not([aria-valuenow]),
::-webkit-date-edit-month-field:not([aria-valuenow]),
::-webkit-date-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.custome-default-btn {
  border: 0;
  background: unset;
}
