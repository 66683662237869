:root {
  --preview-color-secondary-button-text: #000000;
  --preview-color-primary: #6365ef;
  --preview-color-secondary: #f0f0fe;
  --preview-color-primary-font: #303030;
  --preview-color-secondary-font: #949494;
  --preview-color-button: #6365ef;
  --preview-color-secondary-button: #d0d5dd;
  --preview-color-background: #f6f7ff;
  --preview-color-nav-bar: #ffffff;
}

:root.dark-mode-preview {
  --preview-color-primary: #6365ef !important;
  --preview-color-secondary: #1e1e1e !important;
  --preview-color-primary-font: #ffffff !important;
  --preview-color-secondary-font: #949494 !important;
  --preview-color-button: #6365ef !important;
  --preview-color-secondary-button: #1e1e1e !important;
  --preview-color-background: #0d0e0e !important;
  --preview-color-nav-bar: #151616 !important;
}

:root .dark-mode-preview {
  --preview-color-secondary-button-text: #f0f0fe;
}
