.main {
  background: var(--main-background-color);
  height: 100vh;
}

.main::-webkit-scrollbar {
  display: none;
}

.main::-webkit-scrollbar-track {
  display: none;
}

.main-base-container {
  display: flex;

  // Sidebar layout
  .sidebar-main {
    width: 333px;
    height: 100vh;
    padding: 0 20px 0 0;

    @media screen and (max-width: 1900px) {
      width: 260px;
    }

    .admin-sidebar-inner {
      width: 333px;
      padding: 46px 28px;

      @media screen and (max-width: 1900px) {
        width: 260px;
        padding: 30px 16px;
      }

      @media screen and (max-width: 1679px) {
        padding: 100px 18px;
      }
    }

    .super-admin-sidebar-inner {
      width: 248px;
      padding: 46px 10px 45px;

      @media screen and (max-width: 1900px) {
        width: 200px;
        padding: 30px 5px;
      }
    }

    .sidebar-inner {
      height: 100vh;
      background-color: var(--color-white);
      border-right: 1px solid #0000001a;

      .sidebar-brand-parent {
        .sidebar-brand {
          width: 100%;
          height: 40px;
          display: block;

          .brand-logo {
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: left
          }
        }
      }

      .sidebar-menu-list {
        list-style: none;
        margin: 30px 0;
        padding: 0;
        height: calc(100vh - 170px);
        overflow-y: auto;

        @media screen and (max-width: 1679px) {
          height: calc(100vh - 230px);
        }

        .sidebar-main-title {
          display: flex;
          align-items: center;
          margin: 8px auto;
          font-weight: 400;
          font-size: var(--sidebar-text-size);
          font-family: var(--font-family-inter);

          .activeLink {
            border-radius: 8px;
            position: relative;
            overflow: hidden;

            &::before {
              content: '';
              position: absolute;
              inset: 0;
              background-color: var(--color-primary);
              opacity: 10%;
            }
          }

          div a {
            position: relative;

            &:hover {
              &::before {
                content: '';
                position: absolute;
                inset: 0;
                background-color: var(--color-primary);
                opacity: 10%;
                border-radius: 8px;
              }
            }
          }
        }

        a,
        .sidebar-title {
          text-decoration: none;
          color: var(--color-dark);
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 10px 5px;

          @media screen and (max-width: 1900px) {
            font-size: 12px;
            padding: 5px 5px;
          }

          .link-icon {
            width: 20px;
            display: flex;
            justify-content: center;
            margin-right: 12px;

            @media screen and (max-width: 1900px) {
              width: 18px;
              margin-right: 8px;
            }
          }

          .arrow-link-icon {
            display: flex;
            justify-content: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0.5rem;

            @media screen and (max-width: 1679px) {
              right: 0.25rem;
            }
          }
        }

        .sidebar-title {
          padding: 10px 5px 10px 0;
        }

        span:hover,
        img:hover {
          background: transparent !important;
          border-radius: 0 !important;
        }

        .child-div {
          width: 82%;
          height: auto;

          ul {
            background: rgba(0, 0, 0, 0.03);
            border-radius: 8px;
            padding: 8px;
          }

          &:hover {
            background: transparent;
            border-radius: 0;
          }
        }
      }

      .meta-footer {
        position: relative;
        bottom: 20px;
        color: var(--color-dark);
        font-weight: 400;
        font-size: var(--sidebar-text-size);
        line-height: 20px;
        padding: 0 10px;
        margin-top: 24px;
      }
    }
  }

  // Main area layout
  .dashboard-main {
    flex: 1;
    padding: 0 0px 20px 0px;
    background-color: var(--color-light-white);
    height: 100vh;

    .navbar-main {
      background: var(--color-light-white);
      position: fixed;
      width: calc(100vw - 333px);
      padding: 20px;
      z-index: 10;

      @media screen and (max-width: 1900px) {
        width: calc(100vw - 260px);
        padding-top: 30px;
        padding-bottom: 12px;
      }
    }
  }
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  width: inherit;
}

// .user-dashboard{
.common-header {
  margin-top: 90px;
  height: calc(100vh - 82px);
  overflow-y: auto;
}

.dashboard-header {
  .info-head-section {
    label {
      font-family: var(--font-family-sf-pro-display);
      color: var(--color-dark);
    }
  }

  .dashboard-head-flex {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    align-items: center;
    min-height: 50px;

    .dashboard-head-text {
      h2 {
        font-size: var(--heading-text-size) !important;
        font-family: var(--font-family-sf-pro-display);
        color: var(--color-primary);
        margin-bottom: 0;
        font-weight: 600;
      }
    }

    .btn-dashboard-head,
    .btn-dashboard-head:active {
      color: #fff;
      background-color: var(--color-button-primary);
      border-radius: 100px;
      padding: 4px 20px 4px 20px;
      height: 50px;
      border: none;
      font-size: 14px;

      @media screen and (max-width: 1900px) {
        padding: 4px 12px;
        height: 40px;
        flex-shrink: 0;
      }

      .btn-add-icon {
        height: 20px;
        width: 20px;
        margin-right: 26px;
      }

      &:hover {
        background-color: var(--color-button-primary);
      }
    }

    .filter-head {
      color: var(--color-light-dark);
      height: 50px;
      border-radius: 13px;
      background: var(--color-white);
      box-shadow: 1px 5px 11px 0px color-mix(in srgb, var(--color-light-primary) 10%, transparent), 6px 19px 19px 0px color-mix(in srgb, var(--color-light-primary) 9%, transparent), 13px 42px 26px 0px color-mix(in srgb, var(--color-light-primary) 6.5%, transparent), 23px 74px 31px 0px color-mix(in srgb, var(--color-light-primary) 3%, transparent), 35px 116px 34px 0px color-mix(in srgb, var(--color-light-primary) 0%, transparent);
      width: 325px;

      img {
        height: 18px;
        width: auto;
      }

      .w-80px {
        width: 80px;
      }

      .rotate-90-deg {
        transform: rotate(90deg);
      }

      .rotate-180-deg {
        transform: rotate(180deg);
      }

      .rotate-n-90-deg {
        transform: rotate(-90deg);
      }

      mat-form-field {
        border: none !important;
      }
    }
  }

  .lang-dropdown {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--color-dark);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: var(--color-dark);
    }
  }

  .dropdown {
    width: 274px;
  }

  .lang-dropdown-option {
    position: absolute;
    left: -58px;
    top: 8px !important;
    padding: 16px;
    overflow: hidden;
    background: var(--color-white);
    box-shadow: 1px 5px 11px 0px color-mix(in srgb, var(--color-light-primary) 10%, transparent), 6px 19px 19px 0px color-mix(in srgb, var(--color-light-primary) 9%, transparent), 13px 42px 26px 0px color-mix(in srgb, var(--color-light-primary) 6.5%, transparent), 23px 74px 31px 0px color-mix(in srgb, var(--color-light-primary) 3%, transparent), 35px 116px 34px 0px color-mix(in srgb, var(--color-light-primary) 0%, transparent);
    width: 285px;
    border: none;
    cursor: pointer;
    border-radius: 13px;

    .lang-dropdown-item {
      color: var(--color-dark);
      padding: 8px;
      border-radius: 8px;
      margin-bottom: 4px;
    }

    .dropdown-item {
      position: relative;


      &:hover {
        background-color: unset;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          background-color: var(--color-primary);
          opacity: 10%;
          border-radius: 8px;
        }
      }
    }

    .lang-dropdown-item-active {
      color: #fff;
    }

    .hover-bg {
      background-color: var(--dropdown-hover-bg-color) !important;
    }
  }
}

.table-headers {
  font-family: 'SF Pro Display Medium' !important;
}

// Subscriber Table
.subscriber-management {
  margin-top: 100px;
  font-family: var(--font-family-sf-pro-display);

  .section-head-text {
    padding: 5px;
    color: var(--color-dark);
    font-weight: 500;
  }

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 404px);
      padding-top: 10px;
      overflow-x: hidden;
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 2fr 3fr 2.5fr 2fr 2fr 125px;
      grid-gap: 1rem;
      font-size: 16px;
    }

    .table-headers {
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 10px;

      path {
        &:not(.color-stroke) {
          fill: var(--color-dark);
        }

        &.color-stroke {
          stroke: var(--color-dark);
        }
      }
    }

    .t-box {
      padding: 14px 0;
      padding-left: 0.5rem !important;
      width: 100%;
      display: flex;
      gap: 0.5vw;
      color: var(--color-dark);

      &:nth-child(2) {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }

      &:nth-child(6) {
        position: relative;
      }

      .subscription-info-icon {
        width: 45px;
        cursor: pointer;
      }

      .scale-1-3 {
        transform: scale(1.3);
      }
    }

    .action-section-last-t-box {
      padding-left: 13px !important;
    }

    .t-body-bg {
      font-size: 1rem;

      .t-box {
        color: var(--color-dark);
        width: 100%;
        height: 70px;
        align-items: center;
        word-break: break-all;

        &:not(.action-section) {
          opacity: 0.55;
        }

        &.activation-code-section {
          line-height: 1.25 !important;
          padding: 2px 15px !important;
          font-size: 0.75rem;
        }

        &.iccid-text {
          font-size: 0.875rem;
        }

        &:nth-child(5) {
          position: relative;
          left: 1vw;
        }
      }

      &:hover,
      .active {
        .t-box {
          opacity: 1;
        }
      }
    }

    .bg-body-table-dark-bg {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .action-section {
      display: flex;
      justify-content: center;
    }

    .user-table-action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      .user-table-action-icon {
        cursor: pointer;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--svg-color);
        }

        &.color-stroke {
          stroke: var(--svg-color);
        }
      }
    }
  }
}

// User Management Table
.user-management {
  font-family: var(--font-family-sf-pro-display);

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 360px);
      padding-top: 10px;
      overflow-x: hidden;
      font-size: 20px;
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 3fr 4fr 2fr 475px;
      grid-gap: 1;
      padding-right: 15px;

      @media screen and (max-width: 1900px) {
        grid-template-columns: 3fr 4fr 2fr 275px;
      }
    }

    .table-headers {
      font-size: 20px;
      font-weight: 500;
      color: var(--color-dark);
      font-family: 'SF Pro Display', sans-serif;


      @media screen and (max-width: 1900px) {
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      @media (max-width: 1679px) {
        padding-bottom: 5px;
        padding-top: 5px;
      }

      svg {
        width: 1.051vw;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--color-dark);
        }

        &.color-stroke {
          stroke: var(--color-dark);
        }
      }
    }

    .t-box {
      padding: 14px 0;
      padding-left: 0.5rem !important;
      width: 100%;
      gap: 0.5vw;

      @media screen and (max-width: 1900px) {
        padding: 5px 0;
      }

      &:nth-child(4) {
        width: 40%;
        margin-left: auto;

        @media screen and (max-width: 1900px) {
          width: 200px;
        }
      }
    }

    .t-body-bg {
      line-height: 50px;
      font-size: 20px;
      color: var(--color-dark);
      font-weight: 500;

      @media screen and (max-width: 1900px) {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
      }

      .t-box:not(.action-section) {
        color: var(--color-dark);
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 70px;

        @media screen and (max-width: 1900px) {
          height: unset;
          display: flex;
          align-items: center;
        }
      }

      &:hover,
      .active {
        .t-box {
          color: var(--color-dark);
        }
      }
    }

    .table-row-border-top {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .user-table-action {
      display: flex;
      align-items: center;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      @media screen and (min-width: 1900px) {
        margin-left: 4px;
      }

      .user-table-action-icon {
        width: 25px;
        margin-right: 25px;
        cursor: pointer;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--color-dark);
        }

        &.color-stroke {
          stroke: var(--color-dark);
        }
      }
    }
  }
}

// Plan Management Table
.plan-management {
  font-family: var(--font-family-sf-pro-display);

  .t-grid-table-new {
    min-height: 500px;

    @media screen and (max-width: 1679px) {
      min-height: 430px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 380px);
      padding-top: 10px;
      overflow-x: hidden;
      font-size: 1rem;
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 4fr 3fr 2.25fr 2.25fr 3.5fr 200px;
    }

    .table-headers {
      font-size: 1rem;
      font-weight: 500;
      color: var(--color-dark);

      path {
        fill: var(--color-dark);
      }
    }

    .t-box {
      padding: 14px 0;
      padding-left: 0.5rem !important;
      width: 100%;
      gap: 0.5vw;
      align-items: center !important;

      .plan-info-icon {
        width: 24px;
        cursor: pointer;
      }
    }

    .t-body-bg {
      .t-box {
        color: var(--color-dark);
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 70px;
        font-weight: 400;

        &:not(.action-section) {
          line-height: 50px;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          margin-left: 5px;
        }
      }

      .plan-info-section>span {
        color: var(--plan-info-text-color);
        font-weight: 400;
        font-size: 0.875rem !important;
        cursor: default;
        z-index: 1;
      }

      &.inactive-plan {
        .t-box:not(:nth-child(5)):not(:nth-child(6)) {
          opacity: 0.55;
        }

        .plan-info-section>span {
          opacity: 0.55;
        }
      }
    }

    .bg-body-table-dark-bg {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .user-table-action {
      display: flex;
      align-items: center;
      width: 175px;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      .user-table-action-icon {
        cursor: pointer;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--color-dark);
        }

        &.color-stroke {
          stroke: var(--color-dark);
        }
      }
    }
  }

  .switch {
    margin-bottom: 0px;
    position: relative;
    display: inline-block;
    width: 27px;
    margin-top: 10px !important;
    height: 18px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .round {
      border-radius: 34px;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid var(--color-dark);
      transition: 0.4s;
      border-radius: 10px;

      &:before {
        background: var(--color-dark);
        position: absolute;
        content: '';
        height: 12px;
        width: 12px;
        border-radius: 50%;
        left: 2px;
        bottom: 2px;
        transition: 0.4s;
      }
    }
  }

  input:checked+.slider {
    background-color: var(--color-active);
    border: 1px solid var(--color-active);

    &:before {
      transform: translateX(10px);
      background: var(--color-white);
    }
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: var(--color-dark) !important;
    opacity: 0.5;
  }
}

.data-usage-report {
  font-family: var(--font-family-sf-pro-display);

  .download-button {
    width: 13vw !important;

    @media screen and (min-width: 1441px) {
      width: 11vw !important;
    }
  }

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 580px);
      padding-top: 10px;
      overflow-x: hidden;
      align-items: center;

      @media screen and (max-width: 1440px) {
        height: calc(97vh - 602px);
      }
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 1.2fr 1.5fr 1fr 1.2fr 1.2fr 1fr;
    }

    .table-headers {
      font-size: 1rem;
      font-weight: 500;

      path {
        fill: var(--color-dark);
      }

      circle {
        fill: var(--color-dark);
      }
    }

    .table-headers-data-allocated {
      fill: var(--color-dark);
    }

    .table-headers-plan-name {
      path {
        fill: var(--color-dark);
        stroke: var(--color-dark);
      }
    }

    .data-remaining {
      path {
        stroke: var(--color-dark);
      }
    }

    .t-box {
      padding: 14px 10px;
      color: var(--color-dark);
      align-items: center;

      .subscription-info-icon {
        width: 45px;
        cursor: pointer;
      }

      .scale-1-3 {
        transform: scale(1.3);
      }

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .t-body-bg {
      font-size: 1rem;

      .t-box {
        color: var(--color-dark);
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 70px;

        &:not(.action-section) {
          opacity: 0.55;
        }
      }

      &:hover,
      .active {
        .t-box {
          opacity: 1;
        }
      }
    }

    .table-row-border-top {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .action-section {
      display: flex;
    }

    .user-table-action {
      display: flex;
      align-items: center;
      width: 90px;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      .user-table-action-icon {
        width: 15px;
        margin-right: 25px;
        cursor: pointer;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--svg-color);
        }

        &.color-stroke {
          stroke: var(--svg-color);
        }
      }
    }
  }
}

.data-usage-report {
  font-family: var(--font-family-sf-pro-display);

  .download-button {
    width: 13vw !important;

    @media screen and (min-width: 1441px) {
      width: 11vw !important;
    }
  }

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 580px);
      padding-top: 10px;
      overflow-x: hidden;
      align-items: center;

      @media screen and (max-width: 1440px) {
        height: calc(97vh - 602px);
      }
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 1.2fr 1.5fr 1fr 1.2fr 1.2fr 1fr;
    }

    .table-headers {
      font-size: 1rem;
      font-weight: 500;

      path {
        fill: var(--color-dark);
        stroke: var(--color-dark);
      }

      circle {
        fill: var(--color-dark);
      }
    }

    .data-remaining {
      path {
        stroke: var(--color-dark);
      }
    }

    .t-box {
      padding: 14px 10px;
      color: var(--color-dark);
      align-items: center;

      .subscription-info-icon {
        width: 45px;
        cursor: pointer;
      }

      .scale-1-3 {
        transform: scale(1.3);
      }

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .t-body-bg {
      font-size: 1rem;

      .t-box {
        color: var(--color-dark);
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 70px;

        &:not(.action-section) {
          opacity: 0.55;
        }
      }

      &:hover,
      .active {
        .t-box {
          opacity: 1;
        }
      }
    }

    .table-row-border-top {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .action-section {
      display: flex;
    }

    .user-table-action {
      display: flex;
      align-items: center;
      width: 90px;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      .user-table-action-icon {
        width: 15px;
        margin-right: 25px;
        cursor: pointer;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--svg-color);
        }

        &.color-stroke {
          stroke: var(--svg-color);
        }
      }
    }
  }
}

.report-notifications {
  font-family: var(--font-family-sf-pro-display);

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 360px);
      padding-top: 10px;
      overflow-x: hidden;
      align-items: center;
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 1.8fr 2.7fr 2.4fr 1.5fr 2.8fr 150px;
    }

    .table-headers {
      font-size: 1rem;
      font-weight: 500;

      path {
        fill: var(--color-dark);
      }

      circle {
        fill: var(--color-dark);
      }

      .person-svg {
        path {
          stroke: var(--color-dark) !important;
          fill: none !important;
        }
      }
    }

    .t-box {
      padding: 14px 10px 14px 0px;
      color: var(--color-dark);
      align-items: center;

      .subscription-info-icon {
        width: 45px;
        cursor: pointer;
      }

      .scale-1-3 {
        transform: scale(1.3);
      }

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .t-body-bg {
      font-size: 1rem;

      .t-box {
        color: var(--color-dark);
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 70px;

        &:not(.action-section) {
          opacity: 0.55;
        }

        .subscription-status {
          opacity: 0.55;
        }
      }

      &:hover,
      .active {
        .t-box {
          opacity: 1;
        }
        .subscription-status {
          opacity: 1;
        }
      }
    }

    .bg-body-table-dark-bg {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .action-section {
      display: flex;
    }

    .user-table-action {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      .user-table-action-icon {
        padding: 0 15px;
        cursor: pointer;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--svg-color);
        }

        &.color-stroke {
          stroke: var(--svg-color);
        }
      }
    }
  }

  .copy-div .copy {
    padding: 4px 8px 4px 0px;
  }
}

// Subscription Management Table
.subscription-management {
  font-family: var(--font-family-sf-pro-display);

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 360px);
      padding-top: 10px;
      overflow-x: hidden;
      align-items: center;
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 1.5fr 1.5fr 1fr 1.2fr 1.2fr 1.1fr 180px;
    }

    .table-headers {
      font-size: 1rem;
      font-weight: 500;

      path {
        fill: var(--color-dark);
      }

      circle {
        fill: var(--color-dark);
      }
    }

    .t-box {
      padding: 14px 10px;
      color: var(--color-dark);
      align-items: center;

      .subscription-info-icon {
        width: 45px;
        cursor: pointer;
      }

      .scale-1-3 {
        transform: scale(1.3);
      }

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .t-body-bg {
      font-size: 1rem;

      .t-box {
        color: var(--color-dark);
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 70px;

        &:not(.action-section) {
          opacity: 0.55;
        }

        .subscription-status {
          opacity: 0.55;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          margin-left: 5px;
        }
      }

      &:hover,
      .active {
        .t-box {
          opacity: 1;
        }
        .subscription-status {
          opacity: 1;
        }
      }
    }

    .bg-body-table-dark-bg {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .action-section {
      display: flex;
    }

    .user-table-action {
      display: flex;
      align-items: center;
      width: 90px;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      .user-table-action-icon {
        width: 15px;
        margin-right: 25px;
        cursor: pointer;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--svg-color);
        }

        &.color-stroke {
          stroke: var(--svg-color);
        }
      }
    }
  }
}

.user-dashboard-table-container {
  background: var(--color-white);
  padding: 20px 10px 20px 20px;
  border-radius: 6px;
  margin: 0 0 10px 0;

  @media screen and (max-width: 1900px) {
    padding: 20px 20px;
  }

  .info-head-section {
    font-family: var(--font-family-sf-pro-display);
    color: var(--color-dark);
    line-height: 1.6;
    text-wrap: nowrap;
    font-size: 14px;
    padding-left: 36px;

    @media screen and (max-width: 1440px) {
      font-size: 12px;
    }

    .heading {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .term {
      font-size: 0.875rem;
      font-weight: 400;
      margin-top: 8px;
    }

    .term-text {
      margin-right: 6vw;
      margin-top: 8px;
    }

    .plan-status {
      margin-left: -6vw;
    }

    .plan-entries {
      margin-left: 5.99vw;
    }

    .input-label {
      color: var(--dark-text-white-v6);
    }

    .ng-select-container {
      padding: 10px 10px 10px 30px;
      background: var(--navbar-ele-bg-color);
      height: 40px;
      width: 160px;
      color: var(--dark-text-white-v6);
      border: none;

      input {
        color: transparent;
      }

      .ng-value-container {
        padding-left: 0 !important;
      }

      .ng-value {
        width: 99% !important;
      }
    }

    .ng-dropdown-panel {
      margin-top: 4px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      border: 1px solid var(--color-input-border) !important;

      .ng-option {
        padding: 10px 30px 10px 30px;
        background: var(--navbar-ele-bg-color);
        width: 160px;
        color: var(--dark-text-white-v6);

        .active {
          color: #43be83 !important;
        }

        .inactive {
          color: #ec5837 !important;
        }
      }

      :hover {
        background-color: var(--plan-status-hover-bg-color) !important;
      }
    }

    .active {
      color: #43be83 !important;
    }

    .inactive {
      color: #ec5837 !important;
    }
  }

  .plan-table-head {
    width: 480px;
  }
}

// Inventory Management Table
.inventory-management {
  font-family: var(--font-family-sf-pro-display);

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 356px);
      padding-top: 10px;
      overflow-x: hidden;
      align-items: center;
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 1.8fr 2.2fr 0.8fr 2.7fr 2fr 2fr 0.9fr 120px;
      column-gap: 8px;
    }

    .table-headers {
      font-size: 1rem;
      font-weight: 500;
      color: var(--color-dark);

      path {
        &:not(.color-stroke) {
          fill: var(--color-dark);
        }

        &.color-stroke {
          stroke: var(--color-dark);
        }
      }

      circle {
        fill: var(--color-dark);
      }
    }

    .t-box {
      padding: 0.75rem 0;
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      grid-template-columns: 1.8fr 2.2fr 0.8fr 2.7fr 2fr 2fr 0.9fr 120px;
      word-break: inherit;
      color: var(--font-color);

      .subscription-info-icon {
        width: 45px;
        cursor: pointer;
      }

      .scale-1-3 {
        transform: scale(1.3);
      }

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .t-body-bg {
      font-size: 0.75rem;
      font-weight: 400;

      .t-box {
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        font-style: normal;
        line-height: 19px;

        &:not(.action-section) {
          color: var(--color-dark);
          opacity: 0.55;
        }

        &.activation-code-section {
          line-height: 1.25 !important;
          padding: 2px 0 !important;
        }
      }

      &:nth-child(6) {
        word-spacing: 8px;
      }

      &:hover,
      .active {
        .t-box {
          opacity: 1;
        }
      }
    }

    .bg-body-table-dark-bg {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .action-section {
      display: flex;
      align-items: center;
    }

    .user-table-action {
      display: flex;
      align-items: center;
      width: 90px;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      .user-table-action-icon {
        margin-right: 25px;
        cursor: pointer;
      }
    }
  }
}

// Loyalty program page
.loyalty-point-program {
  font-family: var(--font-family-sf-pro-display);

  .custom {
    opacity: 0.5 !important;
    background-color: transparent !important;
  }

  .main-content-div-area {
    margin-top: 63px !important;
    font-family: var(--font-family-sf-pro-display) !important;
    background: var(--color-white) !important;
  }

  .main-base-container {
    .dashboard-main {
      overflow-y: unset !important;
    }
  }

  .main-base-container {
    .dashboard-main {
      overflow-y: unset !important;
    }
  }

  .add-edit-modal {
    padding: 24px 13px 0px 13px;

    .info-modal {
      padding: 24px 14px;
    }
  }

  .dashboard-graph {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .loyalty-content-div-area {
    margin-top: 100px !important;
    font-family: var(--font-family-sf-pro-display) !important;

    .heading-flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    .heading-text h2 {
      font-size: var(--heading-text-size);
      color: var(--color-dark);
      font-weight: 600;
    }
  }

  .loyalty-point-program-reward-section {
    background: var(--color-white);
    width: 100%;
    height: auto;
    border-radius: 20px;
    padding: 15px;
    margin-left: 4px;
    box-shadow: var(--box-shadow-size-color);
  }

  .heading {
    color: var(--color-dark);
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .sub-heading {
    color: var(--color-dark);
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .sub-heading-reward-value {
    width: 100%;
    font-size: 16px;
  }

  .sub-heading-reward-earning {
    width: 100%;
    font-size: 16px;
  }

  .divHeight,
  .input-card-bg {
    flex-shrink: 0 !important;
    padding: 17px;
    margin-bottom: 4px !important;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 10px !important;
      background: var(--color-primary) !important;
      opacity: 10%;
    }
  }

  .error-text {
    font-size: 15px;
  }

  .label {
    color: var(--color-dark) !important;
    font-size: 19px !important;
    font-weight: 500 !important;
  }

  .price-icon {
    display: flex;
    padding: 10px 7px;
    width: 2vw;
    align-items: center;
    background-color: var(--status-filter-bg);
    border-radius: 8px 0px 0px 8px;
    border: 1px solid var(--color-input-border);
  }

  .input-field {
    display: flex;
    font-weight: bold;
    width: 9vw;
    padding: 9px 17px;
    align-items: center;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid var(--color-input-border);
    border-left: 0px;
    border-right: 0px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    background-color: var(--status-filter-bg);
    color: var(--color-dark);

    &:focus-visible {
      outline: unset;
    }
  }

  .disabled-input {
    pointer-events: none;
    cursor: not-allowed;
  }

  .border-red {
    border-color: red !important;
  }

  .input-number-btn {
    display: flex;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    gap: 10px;
  }

  .input-number-btn-reward {
    display: flex;
    padding: 11px 0px;
    align-items: center;
    gap: 11px;
    background-color: var(--status-filter-bg);
    border-radius: 0px 8px 8px 0px;
    border: 1px solid var(--color-input-border);
    border-left: 0px;
    width: 62px;
    height: 44px;
  }

  .input-number-btn-cash {
    left: 72% !important;
    margin-top: -28px !important;
  }

  .number-decremental {
    height: 18px;
    width: 18px;
    line-height: 15px;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #d32f2f;
    color: #d32f2f;
    cursor: pointer;
  }

  .number-incremental {
    height: 18px;
    width: 18px;
    line-height: 15px;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #00c853;
    color: #00c853;
    cursor: pointer;
    margin-left: 0px;
    margin-right: 10px;
  }

  .loyalty-point-program-form {
    path {
      &:not(.color-stroke) {
        fill: var(--color-dark);
      }

      &.color-stroke {
        stroke: var(--color-dark);
      }
    }
  }
}

// Customer Management Table
.customer-management {
  font-family: var(--font-family-sf-pro-display);
  padding-left: 20px;
  padding-right: 40px;

  .customer-header {
    padding-right: 8px !important;
  }

  .section-head-text {
    padding: 5px;
    color: var(--color-dark);
    font-weight: 500;
  }

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 354px);
      padding-top: 10px;
      overflow-x: hidden;
      padding-right: 10px;
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 4fr 4fr 4fr 4fr 175px;
      grid-gap: 1;
    }

    .table-headers {
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--color-dark);
      padding-right: 15px;

      path {
        &:not(.color-stroke) {
          fill: var(--color-dark);
        }

        &.color-stroke {
          stroke: var(--color-dark);
        }
      }
    }

    .t-box {
      padding: 14px 0;
      padding-left: 0.5rem !important;
      width: 100%;
    }

    .t-body-bg {
      line-height: 50px;
      font-size: 1rem;
      font-weight: 400;

      .t-box:not(.action-section) {
        color: var(--color-dark);
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 70px;
      }

      &:not(.disable-customer):hover,
      .active {
        .t-box {
          color: var(--color-dark);
        }
      }
    }

    .active-color {
      color: var(--color-dark) !important;
    }

    .table-row-border-top {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .action-section {
      display: flex;
    }

    .user-table-action {
      display: flex;
      align-items: center;
      width: 90px;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      .user-table-action-icon {
        cursor: pointer;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--color-dark);
        }

        &.color-stroke {
          stroke: var(--color-dark);
        }
      }
    }

    .btn-outline-custom {
      color: var(--font-color);
      border: 1px solid var(--add-button-bg-color);
      border-radius: 25px;
      font-size: 0.8rem;

      &:hover {
        background-color: var(--add-button-bg-color);
        border: none;
        color: #fff !important;
      }
    }
  }

  .switch {
    margin-bottom: 0px;
    position: relative;
    display: flex;
    width: 27px;
    height: 18px;
    align-items: center;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .round {
      border-radius: 34px;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid var(--color-dark);
      transition: 0.4s;
      border-radius: 10px;

      &:before {
        background: var(--color-dark);
        position: absolute;
        content: '';
        height: 12px;
        width: 12px;
        border-radius: 50%;
        left: 2px;
        bottom: 2px;
        transition: 0.4s;
      }
    }
  }

  input:checked+.slider {
    background-color: var(--color-active);
    border: 1px solid var(--color-active);

    &:before {
      transform: translateX(10px);
      background: var(--color-white);
    }
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .btn-dashboard-head {
    width: auto !important;
  }

  .sub-customer-view-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed var(--color-dashed-gray);

    .breadcrumb {
      &-item {
        font-family: 'SF Pro Display';
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        color: var(--color-primary);

        &::before {
          content: ' ';
        }

        @media screen and (max-width: 1900px) {
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }

        .breadcrumb-arrow-container {
          svg {
            path {
              fill: var(--color-primary);
            }
          }
        }
      }

      .active {
        color: rgba(99, 101, 239, 1);
      }
    }
  }
}

.darkMode {
  .filter-head {
    background: var(--color-white);
  }
}

.disabled {
  opacity: 0.8;
  pointer-events: none;
  cursor: not-allowed;
}

// Search form
.search-form {
  width: 22vw;
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .form-control {
    border: none;
    border-radius: 25px;
    font-family: var(--font-family-inter);
    font-size: 0.875rem;
    color: var(--color-dark);
    padding-left: 2.6rem;
    width: 349px !important;
    height: 40px;
    position: relative;
    background-color: var(--color-light-gray);

    &:focus,
    &:hover {
      background-color: var(--color-light-gray);
      color: var(--color-dark);
      box-shadow: none;
    }
  }

  .search-icon-div {
    position: absolute;
    left: 1.9rem;
    transform: translate(-45%, -50%);
    width: 1.25rem;
    top: 48%;

    path {
      fill: var(--color-dark) !important;
    }
  }
}

// Modal Common CSS
.add-edit-modal,
.info-modal {
  padding: 24px 20px;
  font-family: var(--font-family-sf-pro-display);

  .btn {
    height: 44px;
    width: 170px;
    border: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: var(--font-family-inter);

    @media screen and (max-width: 1900px) {
      height: 36px;
      font-size: 14px;
    }
  }

  .btn-secondary {
    background: var(--popup-static-color-button-secondary);
    color: var(--color-dark);
    box-shadow: 0px 1px 2px 0px #1018280D;
    border: 1px solid #D0D5DD;

    &:active {
      background: transparent;
      color: var(--color-dark);
      border: 1px solid var(--color-button-secondary);
    }
  }

  .btn-primary {
    background: var(--color-button-primary) !important;

    &:hover {
      background-color: var(--color-button-primary) !important;
    }
  }

  .btn-full-width {
    width: 100% !important;
  }

  // Subscription Header Icon
  .subscription-header-icon {
    display: flex;
    border: 1px solid var(--color-input-border);
    border-radius: 12px;
    height: 54px;
    width: 54px;

    path {
      fill: var(--color-dark);
    }
  }

  .display-name {
    width: 17vw;
  }

  // Subscription Info Icon
  .subscription-info-icon {
    background: var(--dark-info-background);
    border: 8px solid var(--dark-info-border);
    border-radius: 28px;
    width: 48px;
    height: 48px;

    svg {
      transform: scale(1.2);
    }
  }

  // Subscription info body
  .es-modal-body {
    color: var(--color-dark);

    .row:not(last-child) {
      margin: auto;

      div {
        padding-left: 0 !important;
      }
    }

    .row:last-child {
      border-bottom: none;
    }

    .sub {
      border-bottom: none !important;
    }

    .label-text {
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--color-dark);
    }

    .cloud-icon img {
      position: relative;
      top: 16px;
      right: 52px;
      transform: scale(1);
    }

    .modal-label-text {
      color: var(--color-dark);
      font-size: 14px;
      font-weight: 600;
    }

    .modal-details-text {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-dark);
      word-break: break-word;

      &.total-data {
        font-weight: 600;
        font-size: 18px;
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 22px;
        border-radius: 10px;

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .round {
          border-radius: 34px;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid var(--dark-text-white-v2);
          transition: 0.4s;
          border-radius: 10px;

          &:before {
            background: var(--dark-text-white-v2);
            position: absolute;
            content: '';
            height: 14px;
            width: 14px;
            border-radius: 50%;
            left: 2px;
            bottom: 3px;
            transition: 0.4s;
          }
        }
      }

      input:checked+.slider {
        background-color: var(--dark-primary-background);
        border: 1px solid var(--dark-primary-background);

        &:before {
          transform: translateX(14px);
          background-color: var(--color-white);
        }
      }

      .slider.round:before {
        border-radius: 50%;
      }

      .reading-block {
        font-size: 14px !important;
        font-weight: 400 !important;
        transform: translateY(38px) !important;
      }

      .reading-label {
        font-size: 10px !important;
        font-weight: 400 !important;
      }

      // more countries
      .more-countries {
        text-decoration: underline;
      }
    }

    .subscriber-label-text {
      font-size: 14px;
      font-weight: 500;
      padding-left: 0;
      color: var(--color-dark);
      font-style: normal;
      line-height: 20px;
    }

    .subscriber-details-text {
      font-size: 16px;
      font-weight: 500;
      width: 100%;
      color: var(--color-dark);
    }

    .email-lable {
      .copy {
        padding: 4px 8px;
        text-overflow: ellipsis;
        word-break: break-word;

        svg {
          display: none;
        }
      }
    }

    .activation-code-scroll {
      width: 285px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding-left: 0;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }

    .email-ellipsis {
      text-overflow: ellipsis;
      word-break: keep-all;
      white-space: nowrap;
      cursor: pointer;
    }

    .horizontal-line {
      color: black;
      margin: 0 auto;
    }

    .icon {
      cursor: pointer;
    }

    .disable-color {
      color: var(--color-dark);
      font-size: 16px;
      font-weight: 600;
    }
  }

  .fix-height {
    height: 200px !important;
  }

  // Form
  .es-modal-form {
    .es-modal-form-label {
      color: var(--color-dark);
      font-size: 16px;
      font-weight: 600;
    }

    .form-control {

      &[type='number'],
      &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &.price-input {
        padding-left: 48px;
      }

      &.es-select {
        background-color: transparent;
        color: var(--color-dark);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 5rem;
        background-image: url(../icons/select-arrow-light.svg);
        background-repeat: no-repeat;
        background-position: right;
        background-position-x: 90%;
        background-size: 10px;
        border-color: var(--color-input-border);
        opacity: 0.5;

        option {
          color: var(--color-dark) !important;
          background: transparent;
        }

        &:before {
          border-style: solid;
          border-width: 0.25em 0.25em 0 0;
          content: '>';
          display: inline-block;
          height: 0.45em;
          left: 0.15em;
          position: relative;
          top: 0.15em;
          transform: rotate(-45deg);
          vertical-align: top;
          width: 0.45em;
        }

        &:hover {
          color: var(--color-dark);
        }
      }

      &.left-radius-0 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:focus,
      &:hover {
        background-color: var(--color-white);
        color: var(--color-dark);
        box-shadow: none;
      }

      &.validity-select {
        width: 10rem !important;
      }
    }

    .data-input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .unlimited {
      font-size: 14px !important;
      font-weight: 400 !important;
      display: inline-flex;
      gap: 10px;
    }

    .price-input-icon {
      position: absolute;
      border-right: 1px solid var(--color-input-border);
      height: 37px;
      width: 38px;
      text-align: center;
      line-height: 36px;
      color: var(--color-dark) !important;

      svg {
        path {
          fill: var(--color-dark) !important;
          opacity: 0.5;
        }
      }
    }

    .coupon-input-icon {
      position: absolute;
      border-right: 1px solid var(--color-input-border);
      height: 37px;
      width: 38px;
      text-align: center;
      line-height: 36px;
      color: var(--color-dark) !important;
      opacity: 0.5;
    }

    .date-input-icon {
      position: absolute;
      height: 38px;
      width: 38px;
      text-align: center;
      line-height: 36px;
    }

    .input-icon {
      position: relative;

      .down-caret-btn {
        position: absolute;
        height: 38px;
        width: 38px;
        line-height: 36px;
        right: 0;
        text-align: center;
      }
    }

    .input-number-btn {
      display: flex;
      position: relative;
      left: 72%;
      margin-top: -28px;
      width: fit-content;

      .disabled {
        border-color: #868987 !important;
        color: #868987 !important;
      }

      .number-incremental {
        height: 20px;
        width: 20px;
        line-height: 15px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid #00c853;
        color: #00c853;
        cursor: pointer;
        margin-left: 8px;
        margin-right: 14px;
      }

      .number-decremental {
        height: 20px;
        width: 20px;
        line-height: 15px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid #d32f2f;
        color: #d32f2f;
        cursor: pointer;
      }
    }

    // Plan location search
    .ng-search {
      width: 20px;
      height: 20px;
      margin-left: 10px;
      margin-top: -32px;
      position: absolute;
    }

    .ng-select-container {
      padding: 10px 0px;
      background: transparent;
      height: 40px;
      width: 100%;
      color: var(--color-dark);
      border: 1px solid var(--color-input-border) !important;
      border-radius: 8px !important;

      input {
        color: var(--color-dark);
      }

      .ng-arrow {
        transform: rotate(-90deg);
        border-color: var(--color-input-border) transparent transparent;
      }
    }

    .ng-has-value {
      border: 1px solid var(--color-input-border) !important;
    }

    .full-width {
      width: 100%;
    }

    .custom-select::placeholder {
      color: var(--disabled-date-icon-color);
    }

    .imsi-input {
      .ng-select-container {
        border-color: var(--dark-right-border);
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0 !important;

        .ng-value-container {
          overflow-x: auto;
          flex-wrap: nowrap;

          &::-webkit-scrollbar {
            width: 0px;
          }

          .ng-placeholder {
            top: 8px !important;
            padding-bottom: 0 !important;
            padding-left: 3px !important;
            color: var(--color-dark) !important;
            opacity: 0.5;
          }
        }
      }

      .ng-dropdown-panel .scroll-host {
        padding: 8px;
      }

      .ng-option {
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 4px;

        &:hover {
          background-color: var(--dropdown-hover-bg-color) !important;
        }
      }

      .ng-option-selected {
        position: relative;
        background-color: unset;
        padding: 8px;
        border-radius: 8px;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          background-color: var(--color-primary) !important;
          opacity: 10%;
        }
      }

      input[type='radio'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        accent-color: var(--color-button-primary);
        border: 2px solid var(--color-button-primary);
      }

      input[type='radio']:checked::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-color: var(--color-button-primary);
        border-radius: 50%;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .default-currency-ng-select {
      .ng-dropdown-panel {
        margin-top: 6px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        background: var(--color-white);
        border: 1px solid var(--color-input-border);

        .ng-option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 10px 10px 10px;
          background: var(--color-white);
          color: var(--color-dark);
        }
      }

      option {
        color: var(--color-dark) !important;
      }

      .ng-select-container {
        height: 44px;

        .ng-value-container {
          padding-left: 7px !important;
          color: #475467 !important;
        }

        .ng-clear-wrapper {
          display: none !important;
        }
      }

      .ng-value {
        color: var(--color-dark) !important;
        padding: 7px 10px;
        border-radius: 8px;
        background-color: color-mix(in srgb, var(--color-primary) 12%, transparent) !important;
        width: auto !important;
        font-size: 16px;
      }

      .ng-option {
        font-size: 16px;
        font-family: 'SF Pro Display';
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;

        &:hover {
          background-color: var(--color-white) !important;
        }

        &.ng-option-marked {
          background-color: transparent !important;
        }
      }

      .ng-option-disabled {
        flex-direction: row !important;
      }

      .ng-placeholder {
        color: var(--color-dark) !important;
        opacity: 0.5;
      }

      .ng-select-list-item {
        color: var(--color-dark) !important;
        text-wrap: wrap;
      }

      input[type='radio'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        accent-color: var(--color-primary);
        border: 2px solid var(--color-primary);
      }

      input[type='radio']:checked::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-color: var(--color-primary);
        border-radius: 50%;
        position: relative;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
      }
    }

    .currency-list-input {
      .ng-dropdown-panel {
        margin-top: 6px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        border: 1px solid var(--color-input-border);
        background: var(--color-white);

        .ng-option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 10px 10px 10px;
          background: transparent;
          color: var(--color-dark);
          position: relative;

          &:hover {
            &::before {
              content: '';
              position: absolute;
              inset: 0;
              background-color: var(--color-primary);
              opacity: 10%;
            }
          }

          &.ng-option-marked {
            background-color: transparent !important;
          }

          &.ng-option-selected {
            position: relative;

            &:hover {
              &::before {
                content: '';
                position: absolute;
                inset: 0;
                background-color: var(--color-primary);
                opacity: 10%;
              }
            }
          }
        }
      }

      option {
        color: var(--color-dark) !important;
      }

      .ng-select-container {
        border-color: var(--dark-right-border);
        padding-top: 0;
        padding-bottom: 0;
        height: 44px;

        .ng-value-container .ng-placeholder {
          top: 8px !important;
          padding-bottom: 0 !important;
          padding-left: 3px !important;
          color: var(--color-dark) !important;
          white-space: nowrap;
          opacity: 0.5;
        }

        .ng-clear-wrapper {
          display: none !important;
        }
      }

      .ng-value {
        color: var(--color-dark) !important;
        border: none !important;
        padding: 7px 8px !important;
        border-radius: 8px !important;
        background-color: color-mix(in srgb, var(--color-primary) 12%, transparent) !important;
        width: auto !important;
        font-size: 16px !important;

        .ellipsis {
          min-width: 6.25vw !important;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
        border-left: none !important;

        &:hover {
          background-color: transparent !important;
        }
      }

      .ng-dropdown-panel .scroll-host {
        padding: 20px;

        @media screen and (max-width: 1679px) {
          padding: 20px 0;
        }
      }

      .ng-option {
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 4px;
        color: rgba(31, 29, 29, 0.76);
        font-size: 16px;
        font-family: 'SF Pro Display';
        display: flex;
        justify-content: space-between;

        &:hover {
          background-color: var(--color-white) !important;
        }
      }

      .ng-option-selected {
        position: relative;
        background-color: unset;
        padding: 8px;
        border-radius: 8px;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          background-color: var(--color-primary) !important;
          opacity: 10%;
        }
      }

      .ng-placeholder {
        color: var(--color-dark) !important;
        opacity: 0.5;
      }

      .ng-select-list-item {
        color: var(--color-dark) !important;
        text-wrap: wrap;
        margin-left: 1rem;

        @media screen and (max-width: 1679px) {
          margin-left: 1rem;
        }
      }
    }

    .location-input {
      ng-select {
        width: 100%;

        .ng-value {
          position: relative;
          color: var(--color-dark) !important;
          background-color: color-mix(in srgb, var(--color-primary) 12%, transparent) !important;
          padding: 5px;
          border-radius: 5px;
          margin-bottom: 0;
        }
      }

      .ng-select-container {
        border-color: var(--dark-right-border);
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 36px !important;

        .ng-value-container .ng-placeholder {
          top: 8px !important;
          padding-bottom: 0 !important;
          padding-left: 3px !important;
          color: var(--color-dark) !important;
          opacity: 0.5;
        }

        .ng-clear-wrapper {
          display: none !important;
        }
      }

      .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
        border-left: none !important;

        &:hover {
          background-color: transparent !important;
        }
      }

      .ng-dropdown-panel .scroll-host {
        padding: 20px;
      }

      .ng-option {
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 4px;
        color: rgba(31, 29, 29, 0.76);
        font-size: 14px;
        font-family: 'SF Pro Display';

        &:hover {
          background-color: var(--dropdown-hover-bg-color) !important;
        }
      }

      .ng-option-selected {
        position: relative;
        background-color: unset;
        padding: 8px;
        border-radius: 8px;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          background-color: var(--color-white) !important;
          opacity: 10%;
        }
      }
    }

    ::ng-deep {
      .multi-select-counties {
        .ng-select-container {
          border-color: var(--dark-right-border);
          padding-left: 36px;
        }

        .ng-arrow-wrapper {
          display: none;
        }
      }
    }
  }

  // Subscription form date input
  input[type='date'] {
    padding-left: 48px;

    &::-webkit-calendar-picker-indicator {
      display: block;
      background-size: 12px;
      width: 20px;
      transform: translate(50%, 50%);
      top: 80px;
      text-align: center;
    }
  }

  .dropdown-list-input {
    .ng-select-container {
      .ng-value-container .ng-placeholder {
        color: var(--color-dark) !important;
        opacity: 0.5;
      }
    }

    .ng-dropdown-panel {
      margin-top: 6px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      border: none;
      background: var(--color-white);

      .ng-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 10px 10px;
        background: var(--color-white);
        color: var(--color-dark);
      }
    }

    option {
      color: var(--color-dark) !important;
    }

    .ng-select-container {
      border-color: var(--dark-right-border);
      padding-top: 0;
      padding-bottom: 0;
      height: 44px;

      .ng-value-container .ng-placeholder {
        top: 8px !important;
        padding-bottom: 0 !important;
        padding-left: 3px !important;
        color: var(--color-dark) !important;
        opacity: 0.5;
      }

      .ng-clear-wrapper {
        display: none !important;
      }
    }

    .ng-value {
      color: var(--color-dark) !important;
      border: none !important;
      padding: 7px 8px !important;
      border-radius: 8px !important;
      background-color: color-mix(in srgb, var(--color-primary) 12%, transparent) !important;
      width: auto !important;
      font-size: 0.781vw;

      .ellipsis {
        max-width: 6.25vw !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
      border-left: none !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    .ng-dropdown-panel .scroll-host {
      padding: 8px;
    }

    .ng-option-selected {
      position: relative;
      background-color: unset;
      padding: 8px;
      border-radius: 8px;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: var(--color-white) !important;
        opacity: 10%;
      }
    }
  }
}

// Component Content Area Common Css
.main-content-div-area {
  margin-top: 90px;
  font-family: var(--font-family-sf-pro-display);
  padding-left: 20px;
  padding-right: 40px;
  height: calc(100vh - 82px);
  overflow-y: auto;

  @media screen and (max-width: 1900px) {
    padding-right: 20px;
  }

  /* Dashboard CSS - Start */
  .info-head-section {
    label {
      color: var(--color-dark);
    }
  }

  .heading-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .heading-text h2 {
      font-size: var(--heading-text-size);
      color: var(--color-primary);
      font-weight: 600;
    }
  }

  .section-head-text {
    padding: 5px;
    border-bottom: 1px solid var(--add-button-bg-color);
    color: var(--color-primary);
    font-weight: 500;
  }

  .admin-section-head-text {
    padding-left: 0px !important;
    margin-left: 1px;
  }

  .graph-label {
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-primary) !important;
    font-weight: 400;
  }

  .dashboard-border-top {
    border-top: 1px solid #a8a9fd57;
  }

  .dashboard-border-left {
    border-left: 1px solid #a8a9fd57;
  }

  .dashboard-border-right {
    border-right: 1px solid #a8a9fd57;
  }

  .dashboard-border-bottom {
    border-bottom: 1px solid #a8a9fd57;
  }

  .dashboard-heading {
    color: var(--color-dark);
    height: 36px;

    h4 {
      color: var(--color-dark);
      font-weight: 500;
      font-size: 1.5rem;
    }

    @media screen and (max-width: 1679px) {
      h4 {
        font-size: 1.4rem;
      }
    }

    .border-right {
      border-right: 1px solid var(--dark-right-border);
      padding-right: 8px;
      margin-right: 8px;
    }

    .main-legend:before {
      content: '';
      margin-top: 4px;
      margin-right: 5px;
      position: relative;
      display: flex;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: var(--add-button-bg-color);
    }

    .sub-legend {
      &.current-status:before {
        background-color: var(--add-button-bg-color);
      }

      &.previous-status:before {
        background-color: #6b6b73;
      }

      &:before {
        content: '';
        margin-top: 10px;
        margin-right: 5px;
        position: relative;
        display: flex;
        width: 6px;
        height: 6px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
      }
    }

    .selector-filter {
      .ng-select-container {
        background: var(--dropdown-filter);
        height: 34px;
        color: var(--dropdown-text);
        border: none;
        width: 160px;
        border-radius: 8px;

        input {
          color: transparent;
        }
      }

      .ng-dropdown-panel {
        margin-top: 4px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        border: none;
        width: 160px;
        background: var(--dropdown-filter);

        .ng-dropdown-panel-items {
          border-radius: 8px;
        }

        .ng-option {
          background: var(--dropdown-filter);
          color: var(--dropdown-text);
        }
      }
    }
  }

  .revenue-icon {
    width: 20px;
    height: 20px;
  }

  .widget {
    padding-right: calc(var(--bs-gutter-x) * 1.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 1.2) !important;
  }

  .dashboard-border-left.widget-card {
    padding-left: calc(var(--bs-gutter-x) * 1.2 + 0.5rem) !important;
  }

  .widget-card {
    padding-left: calc(var(--bs-gutter-x) * 1.2) !important;
  }

  .dashboard-graph {
    box-shadow: 1px 5px 11px 0px color-mix(in srgb, var(--color-light-primary) 10%, transparent), 6px 19px 19px 0px color-mix(in srgb, var(--color-light-primary) 9%, transparent), 13px 42px 26px 0px color-mix(in srgb, var(--color-light-primary) 6.5%, transparent), 23px 74px 31px 0px color-mix(in srgb, var(--color-light-primary) 3%, transparent), 35px 116px 34px 0px color-mix(in srgb, var(--color-light-primary) 0%, transparent);
    background: var(--color-white);
    width: 97%;
    height: auto;
    border-radius: 20px;
    padding: 15px;
  }

  .admin-dashboard-graph {
    margin-left: 2px;
  }

  .dashboard-widget {
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    height: 160px;
    box-shadow: 1px 5px 11px 0px color-mix(in srgb, var(--color-light-primary) 10%, transparent), 6px 19px 19px 0px color-mix(in srgb, var(--color-light-primary) 9%, transparent), 13px 42px 26px 0px color-mix(in srgb, var(--color-light-primary) 6.5%, transparent), 23px 74px 31px 0px color-mix(in srgb, var(--color-light-primary) 3%, transparent), 35px 116px 34px 0px color-mix(in srgb, var(--color-light-primary) 0%, transparent);
    background: var(--color-white);
    width: 95%;

    .widget-header {
      width: 100%;
      display: flex;
      gap: 0.6vw;

      .widget-text {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: var(--color-dark);
      }

      @media screen and (max-width: 1679px) {
        .widget-text {
          font-size: 18px;
        }
      }

      .widget-icon {
        path {
          fill: var(--color-dark);
        }
      }
    }

    .widget-body {
      padding: 10px 0;
      font-size: 32px;
      font-weight: 600;
      color: var(--color-dark);
    }

    @media screen and (max-width: 1679px) {
      .widget-body {
        font-size: 30px;
      }
    }

    .widget-footer {
      font-size: 12px;

      .wigdet-helper-text {
        color: var(--color-dark);
      }
    }
  }

  /* Dashboard CSS - End */
}

/* Navbar - Start */
@media screen and (max-width: 1679px) {
  .navbar-main .navbar {
    padding: 0 0 0 0 !important;
  }

  .customer-management {
    padding-right: 22px !important;
  }

  .user-management {
    padding: 0 22px 0 20px !important;
  }

  .theme-editor-section[_ngcontent-ng-c3974722910] {
    padding: 0 20px 30px 20px !important;
  }
}


.navbar-main .navbar {
  padding: 0 20px 0 0;

  .navbar-toggler-parent {
    width: 3.25vw;
    height: auto;

    .navbar-toggler {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .navbar-user-div {
    padding-left: 1.5vw;

    .user-name {
      font-family: var(--font-family-sf-pro-display);
      font-size: 30px;
      font-size: 1.257vw;
    }

    .user-dashboard-text {
      font-family: var(--font-family-sf-pro-display);
      font-size: 16px;
    }
  }

  .nav-item {
    margin-right: 1.5vw;
  }

  .nav-item.dropdown .dropdown-toggle {
    font-family: var(--font-family-sf-pro-display);
    font-size: 1.018vw;
    background: var(--navbar-ele-bg-color);
    border-radius: 25px;
    padding-top: 0.05rem !important;
    padding-bottom: 0.05rem !important;
    height: 50px;
    width: 95px;

    @media (max-width: 1919px) {
      padding-top: 0.14rem !important;
      padding-bottom: 0.14rem !important;
    }

    @media (max-width: 1679px) {
      padding-top: 0.275rem !important;
      padding-bottom: 0.275rem !important;
    }

    @media (max-width: 1439px) {
      padding-top: 0.35rem !important;
      padding-bottom: 0.35rem !important;
    }

    &::after {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: auto;
      color: var(--color-dark);
    }

    &:hover {
      background-color: var(--navbar-ele-hover-bg-color);
    }
  }

  .user-name-div {
    font-family: var(--font-family-sf-pro-display);
    font-size: 1rem;
    color: var(--color-dark) !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }

  .user-profile-div {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }

  .raise-ticket {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: var(--color-gray);
      opacity: 0.2;
      border-radius: 50%;
    }
  }

  .help-icon {
    position: relative;
    left: 2px;
    top: 2px;
    height: 44px;
  }

  .navbar-nav {
    margin-right: 6vw;
  }

  .dropdown-menu {
    background-color: var(--color-white) !important;
    left: -7.5vw;

    .dropdown-item {
      color: var(--color-dark) !important;
      cursor: pointer;

      &:hover {
        background-color: var(--color-white) !important;
      }
    }
  }

  .btn-dropdown {
    padding: 0;
  }

  .btn.show,
  .btn:first-child:active {
    border-color: transparent;
  }
}

.enve-toggle-parent {
  display: flex;
  align-items: center;
  height: 100%;

  .switch {
    margin-bottom: 0px;
    position: relative;
    display: inline-block;
    width: 54px;
    margin-right: 10px;
    margin-left: 10px;
    height: 28px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .round {
      border-radius: 34px;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-white);
      transition: 0.4s;

      &:before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: var(--add-button-bg-color);
        transition: 0.4s;
      }
    }
  }

  input:checked+.slider:before {
    transform: translateX(26px);
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .environment-toggle-text-container {
    .helvetica-font-regular {
      font-family: var(--font-family-sf-pro-display);
      font-size: 1.048vw;
      color: var(--dark-text-white-v4);
      margin-bottom: 0;
    }
  }
}

.dark-light-toggle {
  .switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 40px;
    border-radius: 100px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-gray);
    border-radius: 100px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    opacity: 0.2;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 4px;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .dark-mode-icon {
    position: absolute;
    height: 1.75rem;
    width: 1.75rem;
    top: 50%;
    transform: translateY(-50%);
    right: 7%;
    background: #e9eaf0;
    box-shadow:
      0px 0px 4px rgba(233, 234, 240, 0.7),
      0px 0px 4px rgba(36, 39, 44, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .light-mode-icon {
    position: absolute;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: 7%;
    background-color: #24272c;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
  }
}

.language-dropdown {
  width: 80px !important;
  height: 40px !important;
  font-family: var(--font-family-sf-pro-display);
  color: var(--search-placeholder-text-icon-color) !important;

  &:focus,
  &:hover {
    background-color: var(--navbar-ele-hover-bg-color);
    box-shadow: none;
  }
}

.navbar-expand-lg .navbar-toggler {
  @media (min-width: 992px) {
    display: block !important;
  }
}

/* Navbar - End */

/* Loading Spinner - Start */
.lds-roller {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;

  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }

  div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--color-primary);
    margin: -4px 0 0 -4px;
  }

  div:nth-child(1) {
    animation-delay: -0.036s;
  }

  div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }

  div:nth-child(2) {
    animation-delay: -0.072s;
  }

  div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }

  div:nth-child(3) {
    animation-delay: -0.108s;
  }

  div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }

  div:nth-child(4) {
    animation-delay: -0.144s;
  }

  div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }

  div:nth-child(5) {
    animation-delay: -0.18s;
  }

  div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }

  div:nth-child(6) {
    animation-delay: -0.216s;
  }

  div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }

  div:nth-child(7) {
    animation-delay: -0.252s;
  }

  div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }

  div:nth-child(8) {
    animation-delay: -0.288s;
  }

  div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Loading Spinner - End */

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
  color: var(--font-color) !important;
}

.div-height,
.divHeight {
  height: 90px;
  margin-bottom: 4px;
}

.rowHeight {
  min-height: 50px;
}

.rowHeight40 {
  height: 40px;
}

.info-row-height {
  height: 60px;
}

.helper-text {
  color: var(--color-dark) !important;
}

.word-break {
  word-break: break-all !important;
}

.small {
  font-size: 0.6rem !important;
}

.number-input-right-padding {
  padding-right: 4.5rem !important;
  font-size: 1rem !important;
}

.btn-width {
  width: 48% !important;
  gap: 20px;
}

.input-width {
  width: 48% !important;
}

.custom-border-bottom {
  border-bottom: 1px solid var(--color-dashed-gray);
}

.copy-div {
  .copy {
    padding: 4px 8px;

    svg {
      display: none;
    }
  }

  .email-box {
    width: 16vw;
  }

  &:hover {
    .copy {
      border: 1px solid var(--add-button-hover-bg-color);
      border-radius: 4px;

      svg {
        display: inline;
      }
    }
  }
}

.add-customer-icon {
  background: var(--dark-primary-background);
  border: 8px solid var(--dark-primary-border);
  border-radius: 28px;
  width: 56px;

  svg {
    transform: scale(1.5);
  }
}

.edit-customer-icon {
  background: var(--dark-info-background);
  border: 8px solid var(--dark-info-border);
  border-radius: 28px;
  width: 56px;

  svg {
    transform: scale(1.5);
  }
}

.ng-option-marked:hover {
  background-color: var(--navbar-ele-hover-bg-color) !important;
  width: 100%;

  span {
    color: var(--color-dark);
  }
}

.no-data {
  color: var(--color-dark);
}

.revenue-text {
  color: var(--color-dark);
  font-weight: 600;
  opacity: 65%;
}

.dashboard-heading .revenue-text {
  opacity: 1;
}

/* CUSTOM CHECKBOX : Add New Plan - Start */
.unlimited {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.unlimited input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  border: 1px solid var(--color-button-primary);
  width: 16px;
  height: 16px;
  border-radius: 5px;
}

.unlimited input:checked~.checkmark {
  background-color: var(--color-button-primary);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.unlimited input:checked~.checkmark:after {
  display: block;
}

.unlimited .checkmark:after {
  left: 6px;
  bottom: 4px;
  width: 5px;
  height: 12px;
  border: solid var(--color-white);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

/* CUSTOM CHECKBOX : Add New Plan - End */

.show-entries {
  font-size: 15px;
  opacity: 0.6;
}

.show-report-helper {
  font-size: 17px;
  color: var(--color-dark) !important;
  font-family: 'SF Pro Display Medium', sans-serif !important;
  opacity: 0.7;
}

.ng-arrow-wrapper {
  display: none !important;
}

/* ng-select dropdown custom arrow CSS - START */
.container-ng-select {
  position: relative;

  .default {
    position: absolute;
    top: 17px;
    right: 28px;
  }
}

.relative {
  .default path {
    stroke: #667085 !important;
  }
}

/* ng-select dropdown custom arrow CSS - END */

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.w100 {
  width: 100% !important;
}

.mat-date-range-input-separator {
  font-family: var(--font-family-sf-pro-display);
  color: var(--color-dark) !important;
  font-style: normal;
  font-weight: 600;
  margin-left: 10px !important;
  margin-right: 10px !important;
  font-size: 0;

  &::before {
    content: 'to';
    font-family: 'SF Pro Display' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 0.832vw !important;
    line-height: 130% !important;
    text-align: center;
    vertical-align: middle;
  }
}

.mat-date-range-input-inner {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  color: var(--color-dark) !important;
}

.w80 {
  width: 82% !important;
}

.help-contact-management {
  .t-grid-table-wrapper {
    display: grid;
    grid-template-columns: 3fr 4fr 3fr 3fr 3fr 125px;
  }
}

.help-support-management {
  .t-grid-table-wrapper {
    display: grid;
    grid-template-columns: 3fr 4fr 3fr 3fr 3fr 3fr 125px;
  }
}

.help-affiliate-management {
  .t-grid-table-wrapper {
    display: grid;
    grid-template-columns: 3fr 4fr 3fr 3fr 3fr 125px;
  }
}

.help-contact-management,
.help-support-management,
.help-affiliate-management {
  font-family: var(--font-family-sf-pro-display);

  .filter-div {
    position: relative;
    height: 2.6vw;
    border-radius: 13px;
    background: var(--popup-static-color-button-secondary);
    box-shadow: 1px 5px 11px 0px color-mix(in srgb, var(--color-light-primary) 10%, transparent), 6px 19px 19px 0px color-mix(in srgb, var(--color-light-primary) 9%, transparent), 13px 42px 26px 0px color-mix(in srgb, var(--color-light-primary) 6.5%, transparent), 23px 74px 31px 0px color-mix(in srgb, var(--color-light-primary) 3%, transparent), 35px 116px 34px 0px color-mix(in srgb, var(--color-light-primary) 0%, transparent);
    padding: 8px;
    width: 10vw;

    .rotate-90-deg {
      transform: rotate(90deg);
    }

    .rotate-180-deg {
      transform: rotate(180deg);
    }

    .rotate-n-90-deg {
      transform: rotate(-90deg);
    }

    .lang-dropdown {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding: 5px 0px !important;
      padding-left: 0px !important;
      color: var(--color-dark);
      text-decoration: none;

      &:hover {
        color: var(--color-dark);
      }
    }

    .lang-dropdown-option {
      position: absolute;
      inset: 4px auto auto 40px !important;
      margin: 0px;
      left: 24px !important;
      padding: 16px;
      overflow: hidden;
      width: 200px !important;
      border: none;
      cursor: pointer;
      border-radius: 13px;
      transform: translate(-25px, 50px) !important;
      background: var(--popup-static-color-button-secondary);
      box-shadow: 1px 5px 11px 0px color-mix(in srgb, var(--color-light-primary) 10%, transparent), 6px 19px 19px 0px color-mix(in srgb, var(--color-light-primary) 9%, transparent), 13px 42px 26px 0px color-mix(in srgb, var(--color-light-primary) 6.5%, transparent), 23px 74px 31px 0px color-mix(in srgb, var(--color-light-primary) 3%, transparent), 35px 116px 34px 0px color-mix(in srgb, var(--color-light-primary) 0%, transparent);

      .lang-dropdown-item {
        color: var(--color-dark);
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 4px;
      }

      .status-item:nth-child(1) {
        color: #149a4b;
      }

      .status-item:nth-child(2) {
        color: #7f8d26;
      }

      .status-item:nth-child(3) {
        color: #ffa600;
      }

      .status-item:nth-child(4) {
        color: #ff323e;
      }

      .dropdown-item {
        &:hover {
          background-color: var(--dropdown-hover-bg-color) !important;
        }
      }

      .lang-dropdown-item-active {
        color: #fff;
      }

      .hover-bg {
        background-color: var(--dropdown-hover-bg-color) !important;
      }
    }
  }

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 380px);
      padding-top: 10px;
      overflow-x: hidden;
      font-size: 1vw;
    }

    .table-headers {
      font-size: 1.042vw;
      font-weight: 500;
      color: var(--color-dark);

      path,
      circle {
        &:not(.color-stroke) {
          fill: var(--color-dark);
        }

        &.color-stroke {
          stroke: var(--color-dark);
        }
      }
    }

    .t-box {
      padding: 14px 0;
      padding-left: 0.5rem !important;
      width: 100%;
      gap: 0.5vw;

      align-items: center !important;

      .plan-info-icon {
        width: 24px;
        cursor: pointer;
        margin-left: 1.4vw;
      }
    }

    .t-body-bg {
      .t-box {
        color: var(--color-dark);
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 3.6vw;
        font-weight: 400;

        &:not(.action-section) {
          line-height: 50px;
        }

        &:nth-child(3),
        &:nth-child(5) {
          margin-left: 2px;
        }

        &:nth-child(4) {
          margin-left: 8px;
        }
      }

      .plan-info-section>span {
        color: var(--plan-info-text-color);
        font-weight: 400;
        font-size: 1.042vw !important;
        cursor: default;
        z-index: 1;
      }

      &.inactive-plan {
        .t-box:not(:nth-child(5)):not(:nth-child(6)) {
          opacity: 0.55;
          color: #475467 !important;
        }

        .plan-info-section>span {
          color: #475467 !important;
        }
      }

      .status-box {
        padding: 0 10px;
      }
    }

    .bg-body-table-dark-bg {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .user-table-action {
      display: flex;
      align-items: center;
      margin-left: 0.8vw;
      width: 4vw;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 2vw;

      .user-table-action-icon {
        width: 15px;
        margin-right: 25px;
        cursor: pointer;
      }
    }

    .custom-label-size {
      margin-top: auto !important;
      margin-bottom: auto !important;
      width: 80%;
      margin-left: 12px;
    }
  }

  .icon-email-support {
    margin-left: auto;
    position: relative;
  }

  .icon-numbercopy {
    margin-left: auto;
    position: relative;
    left: -1.5vw;
  }

  .icon-email-contact {
    margin-left: auto;
    position: relative;
    left: -2vw;
  }

  .copy-icon {
    fill: var(--color-dark);
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: var(--color-dark);
    opacity: 0.5;
  }
}

.w-85 {
  width: 85%;
}

.w-80 {
  width: 80%;
}

.border-bottom {
  border-bottom: 1px solid rgba(168, 169, 253, 0.3) !important;
}

// Coupon Management
.coupon-management {
  font-family: var(--font-family-sf-pro-display);

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 360px);
      padding-top: 10px;
      overflow-x: hidden;
      align-items: center;
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 2fr 2.5fr 1.5fr 1.5fr 1.5fr 1.5fr 140px;
    }

    .table-headers {
      font-size: 20px;
      font-weight: 500;

      .fill-color {
        fill: var(--color-dark);
      }

      .stroke-color {
        stroke: var(--color-dark);
      }

      circle {
        fill: var(--color-dark);
      }
    }

    .t-box {
      padding: 14px 10px;
      color: var(--color-dark);
      align-items: center;

      .subscription-info-icon {
        width: 2.345vw;
        cursor: pointer;
      }

      .scale-1-3 {
        transform: scale(1.3);
      }

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .t-body-bg {
      font-size: 16px;

      .t-box {
        color: var(--color-dark);
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 3.645vw;
        font-weight: 400;

        &:not(.action-section) {
          opacity: 0.55;
        }
      }

      &.active-coupon {
        .t-box:not(:nth-child(7)) {
          opacity: 1;
          color: var(--color-dark) !important;
        }
      }
    }

    .bg-body-table-dark-bg {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .action-section {
      display: flex;
    }

    .user-table-action {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 2.345vw;

      .user-table-action-icon {
        margin-right: 25px;
        cursor: pointer;
      }
    }
  }
}

.coupon-modal {
  .more {
    color: var(--show-more-text-color);
    font-family: 'SF Pro Display';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
  }
}

.custom-search ::ng-deep .ng-placeholder::before {
  font-family: FontAwesome !important;
  content: '\f002' !important;
  margin-right: 8px !important;
}

.fill-color {
  fill: var(--color-dark) !important;
}

.stroke-color {
  stroke: var(--color-dark) !important;
}

.calendar-fill-color {
  fill: var(--color-dark) !important;

  svg {
    path {
      opacity: 0.5;
    }
  }
}

.x-small {
  font-size: x-small !important;
}

.w90 {
  width: 90% !important;
}

.w86 {
  width: 86% !important;
}

.w96 {
  width: 96% !important;
}

/* Add New Coupon Form CSS - START */
.coupon-form {
  .discount-value .input-number-btn {
    left: 90% !important;
  }

  .steps {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;

    div {
      width: 40px;
      height: 40px;
      text-align: center;
      color: var(--color-primary);
      border-radius: 50%;
      border: 1px solid var(--color-primary);
      background: #ffffff;
      padding: 8.296px;
    }

    .active {
      color: #ffffff;
      border: 1px solid var(--color-primary);
      background: var(--color-primary) !important;
    }

    .line {
      height: 1px;
      background-color: var(--color-primary) !important;
      margin: auto 0;
      width: 40%;
      opacity: 20%;
    }
  }

  .custom-radio-div {
    display: flex;
    width: 48%;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--color-primary);
    margin-right: 0 !important;
  }

  .custom-radio-div-inline-3 {
    display: flex;
    padding: 10px 6px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--color-input-border);
    margin-right: 0 !important;
  }

  .custom-radio-div-inline-3:nth-child(1) {
    width: 19%;
  }

  .custom-radio-div-inline-3:not(:nth-child(1)) {
    width: 25%;
  }

  .custom-radio-div-block {
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--color-input-border);
    margin-right: 0 !important;
  }

  input::placeholder {
    color: var(--color-dark);
    font-size: 16px;
    font-weight: 400;
  }

  label {
    color: var(--color-dark);
  }

  input[type='radio'] {
    appearance: none;
    margin: 0;
    font: inherit;
    color: var(--color-button-primary);
    width: 20px;
    height: 20px;
    border: 2px solid var(--color-button-primary);
    border-radius: 50%;
    display: grid;
    place-content: center;
    top: 46%;
    right: 10px;
  }

  input[type='radio']::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--color-button-primary);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  .checked {
    border: 1px solid var(--color-primary);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: var(--color-primary);
      opacity: 10%;
      border-radius: 8px;
    }
  }

  .total-use-number-button {
    position: absolute;
    top: 46%;
    left: 82% !important;
    display: flex;
    width: fit-content;

    .disabled {
      border-color: #868987 !important;
      color: #868987 !important;
    }

    .number-incremental {
      height: 20px;
      width: 20px;
      line-height: 15px;
      border-radius: 50%;
      text-align: center;
      border: 1px solid #00c853;
      color: #00c853;
      cursor: pointer;
      margin-left: 8px;
      margin-right: 14px;
    }

    .number-decremental {
      height: 20px;
      width: 20px;
      line-height: 15px;
      border-radius: 50%;
      text-align: center;
      border: 1px solid #d32f2f;
      color: #d32f2f;
      cursor: pointer;
    }
  }

  .use-div {
    border-radius: 10px;
    background: rgba(105, 112, 241, 0.1);
    padding: 10px;
    position: relative;

    &::before {
      position: absolute;
      inset: 0;
      background: var(--color-primary);
      opacity: 10%;
      content: '';
    }
  }

  .fill-none {
    fill: none !important;
  }

  .fill-color {
    fill: var(--color-dark);
  }

  .stroke-color {
    stroke: var(--color-dark);
  }

  .position-absolute {
    position: absolute;
    top: 36px;
    right: 10px;
  }

  .input-number-btn .total-use-number-button {
    left: 0 !important;
    right: 8px !important;
  }

  .use-div {
    .price-input {
      background: var(--color-white);
    }

    .use-count-div {
      background-color: var(--color-white);
      border-radius: 8px;
    }
  }

  .min-max-value {
    left: 79% !important;
  }

  .number-input-right-padding {
    padding-right: 6.5rem !important;
  }

  .flag-style {
    width: 32px;
    height: 22px;
    border-radius: 4px !important;
    margin-right: 4px;
  }

  .dropdown-list-input {
    .ng-select-container {
      border-color: var(--dark-right-border);
      padding-top: 0;
      padding-bottom: 0;
      height: 44px;

      .ng-value-container .ng-placeholder {
        top: 8px !important;
        padding-bottom: 0 !important;
        padding-left: 3px !important;
        color: var(--color-dark) !important;
        opacity: 0.5;
      }

      .ng-clear-wrapper {
        display: none !important;
      }
    }

    .ng-value {
      color: var(--color-dark) !important;
      border: none !important;
      padding: 4px 8px !important;
      border-radius: 8px !important;
      background-color: color-mix(in srgb, var(--color-primary) 10%, transparent) !important;
      width: auto !important;
      display: flex;
      align-items: center;

      .ellipsis {
        max-width: 6.25vw !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ng-select .right {
      border-left: none !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    .ng-dropdown-panel .scroll-host {
      padding: 20px;
    }

    .ng-option {
      padding: 8px;
      border-radius: 8px;
      margin-bottom: 4px;
      color: rgba(31, 29, 29, 0.76);
      font-size: 12px;
      font-family: SF Pro Display;

      &:hover {
        background-color: var(--dropdown-hover-bg-color) !important;
      }
    }

    .ng-option-selected {
      position: relative;
      background-color: unset;
      padding: 8px;
      border-radius: 8px;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: var(--color-white) !important;
        opacity: 10%;
      }
    }
  }
}

.custom-search ::ng-deep .ng-placeholder::before {
  font-family: FontAwesome !important;
  content: '\f002' !important;
  margin-right: 8px !important;
  color: var(--color-dark);
}

.number-of-use-icon {
  position: absolute;
  border-right: 1px solid var(--color-input-border);
  height: 37px;
  width: 38px;
  text-align: center;
  line-height: 36px;
  color: var(--color-dark) !important;
  opacity: 0.5;
}

/* Add New Coupon Form CSS - END */

.fs14 {
  font-size: 14px;
  font-weight: 400;
}

.fill-color {
  fill: var(--color-dark);
}

.stroke-color {
  stroke: var(--color-dark);
}

.word-wrap {
  word-wrap: break-word !important;
}

.max-w80 {
  max-width: 100% !important;
}

.gap-50 {
  gap: 50px;
}

.pos-top {
  position: relative;
  top: -7px;
}

// all agents svg
.all-agents-svg {
  path {
    fill: var(--color-primary);
  }

  rect {
    fill: var(--color-primary);
  }
}

// SA - Customer CSS
.customer-form-header {
  svg {
    path {
      fill: var(--color-dark);
    }

    rect {
      fill: var(--color-dark);
    }
  }

  .back-btn {
    color: var(--color-dark);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.4px;

    svg {
      fill: var(--color-dark);
      width: 18px;
      height: 18px;
    }
  }

  .btn:active,
  .back-btn:active,
  .action-btn:active {
    color: var(--color-dark);
    border: none !important;
  }

  .action-btn {
    width: 126px;
    height: 58px;
    padding: 13px 31px 13px 30px;
    border-radius: 100px;
    background-color: var(--color-button-secondary);
    color: var(--color-dark);
    text-align: center;
    font-family: 'SF Pro Display';
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    @media screen and (max-width: 1900px) {
      padding: unset;
      height: 40px;
      font-size: 18px;
    }

    &:active {
      background-color: var(--color-button-secondary);
    }
  }
}

.action-btn-container {
  display: flex;
  justify-content: flex-end;
}

.customer-form {
  height: 80vh;

  .right-div {
    input {
      height: 44px;
      background-color: transparent !important;
      color: var(--color-dark);
      border: 1px solid var(--color-input-border);

      @media screen and (max-width: 1900px) {
        font-size: 14px;
        height: 44px;
      }

      &::placeholder {
        opacity: 0.5;
      }
    }

    .form-check {
      height: 56px;
      border-radius: 8px;
      background: rgba(99, 101, 239, 0.05);
    }

    .form-control,
    .phone-number-form-control {
      border-color: var(--color-input-border);

      &:focus {
        background-color: transparent !important;
        border-color: var(--color-input-border);
        outline: 0;
        box-shadow: none !important;
      }
    }
  }

  .left-div,
  .right-div {
    border-radius: 6px;
    background-color: var(--color-white);
    box-shadow: 1px 5px 11px 0px color-mix(in srgb, var(--color-light-primary) 10%, transparent), 6px 19px 19px 0px color-mix(in srgb, var(--color-light-primary) 9%, transparent), 13px 42px 26px 0px color-mix(in srgb, var(--color-light-primary) 6.5%, transparent), 23px 74px 31px 0px color-mix(in srgb, var(--color-light-primary) 3%, transparent), 35px 116px 34px 0px color-mix(in srgb, var(--color-light-primary) 0%, transparent);
    min-height: calc(100vh - 220px);
  }

  .steps {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 220px);

    .circle {
      width: 40px;
      height: 40px;
      text-align: center;
      color: #a5a6ee;
      border-radius: 50%;
      border: 1px solid #a5a6ee;
      background: var(--color-white);
      padding: 8.296px;
      z-index: 2;
      flex-shrink: 0;
    }

    .active {
      color: #ffffff;
      border: 1px solid var(--color-primary);
      background: var(--color-primary) !important;
    }
  }

  input::placeholder {
    color: var(--color-dark);
    font-size: 16px;
    font-weight: 400;
  }

  label {
    color: var(--color-dark) !important;
    font-family: 'SF Pro Display' !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 130% !important;

    @media screen and (max-width: 1900px) {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }

  .use-div {
    border-radius: 10px;
    background: rgba(105, 112, 241, 0.1);
    padding: 10px;
  }

  .fill-none {
    fill: none !important;
  }

  .fill-color {
    fill: var(--color-dark);
  }

  .stroke-color {
    stroke: var(--color-dark);
  }

  .position-absolute {
    position: absolute;
    top: 36px;
    right: 10px;
  }

  .line {
    display: block;
    height: 1px;
    background-color: #a5a6ee !important;
    margin-left: -90%;
    transform: rotate(90deg);
  }

  .title-section {
    h2 {
      color: var(--color-dark);
      font-family: 'SF Pro Display';
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;

      @media screen and (max-width: 1900px) {
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    p {
      color: var(--color-dark);
      opacity: 0.5;
      font-family: 'SF Pro Display';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.32px;

      @media screen and (max-width: 1900px) {
        font-size: 14px;
      }
    }

    .form-group-title {
      font-family: 'SF Pro Display';
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .border-error {
    border: 1px solid rgb(225, 112, 112) !important;
  }
}

// Agent Management Table
.agent-management {
  font-family: var(--font-family-sf-pro-display);

  .customer-header {
    padding-right: 8px !important;
  }

  .section-head-text {
    padding: 5px;
    color: var(--color-dark);
    font-weight: 500;
  }

  .t-grid-table-new {
    @media screen and (max-width: 1500px) {
      min-width: 470px;
    }

    .t-body-overflow {
      overflow: auto;
      height: calc(97vh - 354px);
      padding-top: 10px;
      overflow-x: hidden;
    }

    .t-grid-table-wrapper {
      display: grid;
      grid-template-columns: 4fr 4fr 4fr 175px;
      grid-gap: 1;
    }

    .table-headers {
      font-size: 1rem;
      font-weight: 500;
      color: var(--color-dark);

      path {
        &:not(.color-stroke) {
          fill: var(--color-dark);
        }

        &.color-stroke {
          stroke: var(--color-dark);
        }
      }
    }

    .t-box {
      padding: 14px 0;
      padding-left: 0.5rem !important;
      width: 100%;
    }

    .t-body-bg {
      line-height: 50px;
      font-size: 1rem;
      font-weight: 400;

      .t-box:not(.action-section) {
        color: var(--color-dark);
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit;
        width: 100%;
        height: 70px;
      }

      &:hover,
      .active {
        .t-box {
          color: var(--color-dark);
        }
      }
    }

    .active-color {
      color: var(--color-dark) !important;
    }

    .table-row-border-top {
      border-top: 1px dashed var(--color-dashed-gray);
    }

    .action-section {
      display: flex;
    }

    .user-table-action {
      display: flex;
      align-items: center;
      width: 55px;
      background-color: var(--color-action-gray);
      border-radius: 40px;
      height: 45px;

      .user-table-action-icon {
        cursor: pointer;
      }

      path {
        &:not(.color-stroke) {
          fill: var(--svg-color);
        }

        &.color-stroke {
          stroke: var(--svg-color);
        }
      }
    }

    .btn-outline-custom {
      color: var(--font-color);
      border: 1px solid var(--add-button-bg-color);
      border-radius: 25px;
      font-size: 0.8rem;

      &:hover {
        background-color: var(--add-button-bg-color);
        border: none;
        color: #fff !important;
      }
    }
  }

  .switch {
    margin-bottom: 0px;
    position: relative;
    display: flex;
    width: 27px;
    height: 18px;
    align-items: center;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .round {
      border-radius: 34px;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid var(--color-dark);
      transition: 0.4s;
      border-radius: 10px;

      &:before {
        background: var(--color-dark);
        position: absolute;
        content: '';
        height: 12px;
        width: 12px;
        border-radius: 50%;
        left: 2px;
        bottom: 2px;
        transition: 0.4s;
      }
    }
  }

  input:checked+.slider {
    background-color: var(--color-active);
    border: 1px solid var(--color-active);

    &:before {
      transform: translateX(10px);
      background-color: var(--color-white);
    }
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .btn-dashboard-head {
    width: auto !important;
  }

  .sub-customer-view-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed var(--color-dashed-gray);

    .breadcrumb {
      &-item {
        font-family: 'SF Pro Display';
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        color: #000;
      }

      .active {
        color: rgba(99, 101, 239, 1);
      }
    }
  }
}

.flag-style {
  width: 32px;
  height: 22px;
  border-radius: 4px !important;
  margin-right: 4px;
  object-fit: cover;
}

.info-icon-div {
  svg {
    path {
      fill: var(--color-primary) !important;
    }
  }
}

::ng-deep.mdc-button__label {
  color: var(--color-dark);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border: unset !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
  border: unset !important;
}

.dropdown-item {
  cursor: pointer;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: var(--color-dark) !important;
  opacity: 0.5;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--color-dark) !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: var(--color-dark) !important;
  transition: background-color 5000s ease-in-out 0s;
}

.dashboard-insider-head-text h2 {
  color: var(--color-dark) !important;
}

.unlimited-plan-indicator {
  display: inline-block;
  padding: 5px 8px;
  border: 1px solid var(--color-primary) !important;
  border-radius: 5px;
  background-color: color-mix(in srgb, var(--color-primary) 10%, transparent);
  font-size: 12px;
  margin-bottom: 7px;
  color: var(--color-dark)
}
