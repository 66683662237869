// Super Admin sidebar ////////////////////////////////////////////////////////////////////////////////

.sidebar-main {
  .super-admin-main {
    display: flex;
    width: 333px;

    @media screen and (max-width: 1900px) {
      width: 260px;
    }

    .super-admin-sidebar {
      width: 333px;
      flex-shrink: 0;
      height: 100vh;
      padding: 46px 28px;
      background-color: var(--color-white);

      @media screen and (max-width: 1900px) {
        width: 260px;
        padding: 30px 16px;
      }

      .sidebar-brand-parent {
        .brand-logo {
          height: 42px;
          width: auto;

          @media screen and (max-width: 1900px) {
            height: 38px;
          }
        }

        .brand-logo-small {
          display: none;
        }
      }

      .sidebar-menu-list {
        list-style: none;
        margin: 30px 0;
        padding: 0;
        height: calc(100vh - 170px);
        overflow-y: auto;

        .sidebar-main-title {
          align-items: center;
          margin: 8px auto;
          font-weight: 400;
          font-size: var(--sidebar-text-size);
          font-family: var(--font-family-inter);

          .activeLink {
            border-radius: 8px;
            position: relative;
            overflow: hidden;

            &::before {
              content: '';
              position: absolute;
              inset: 0;
              background-color: #6365ef;
              opacity: 10%;
            }
          }

          div a {
            position: relative;

            &:hover {
              &::before {
                content: '';
                position: absolute;
                inset: 0;
                background-color: var(--color-primary);
                opacity: 10%;
                border-radius: 8px;
              }
            }
          }
        }

        a,
        .sidebar-title {
          text-decoration: none;
          color: var(--color-dark);
          display: block;
          width: 100%;
          height: 100%;
          padding: 10px 5px;
          display: flex;
          align-items: center;

          @media screen and (max-width: 1900px) {
            padding: 5px;
          }

          .link-icon {
            width: 24px;
            display: flex;
            justify-content: center;
            margin-right: 12px;
          }

          .arrow-link-icon {
            display: flex;
            justify-content: center;
            margin-right: 4px;

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0.5rem;
          }
        }

        span:hover,
        img:hover {
          background: transparent !important;
          border-radius: 0 !important;
        }

        .child-div {
          width: 82%;
          height: auto;

          ul {
            background: rgba(0, 0, 0, 0.03);
            border-radius: 8px;
            padding: 8px;
          }

          &:hover {
            background: transparent;
            border-radius: 0;
          }
        }
      }

      .meta-footer {
        position: relative;
        bottom: 20px;
        color: var(--color-dark);
        font-weight: 400;
        font-size: var(--sidebar-text-size);
        line-height: 20px;
        padding: 0 10px;
        margin-top: 24px;
      }
    }

    .super-admin-sidebar.super-admin-customer-selected {
      width: 85px !important;
      padding: 46px 13px !important;
      background-color: var(--color-bg-min-sidebar);

      @media screen and (max-width: 1900px) {
        width: 60px !important;
        padding: 30px 8px !important;
      }

      .brand-logo {
        display: none;
      }

      .brand-logo-small {
        display: block;

        @media screen and (max-width: 1900px) {
          width: 40px;
          margin: auto;
        }
      }

      .sidebar-title .link-icon {
        margin-right: 0;
        margin: auto;
      }

      .sidebar-menu-title {
        display: none;
      }

      .meta-footer {
        display: none !important;
      }

      .accordian-base-1 {
        display: none;
      }

      .sidebar-anchor-tab-flex-text-container.arrow-link-icon {
        display: none;
      }
    }

    .sidebar-inner {
      .customer-name {
        color: var(--color-primary);
        font-family: 'Inter';
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-decoration: none;
      }
    }
  }
}