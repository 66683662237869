.auth-parent-section {
  height: 100vh;
  position: relative;

  .auth-main-body {
    background-image: url('/assets/images/auth/bg.svg');
    width: 100%;
    margin: auto;
    position: absolute;
    overflow: hidden;

    .z-index-3 {
      z-index: 3;
    }

    .z-index-2 {
      z-index: 2;
    }

    .auth-right-section {
      background: #ffffff;
      padding: 4.5vw 3.85vw 0.75vw;
      height: 100vh;

      .logo-span {
        width: 9vw;
        height: auto;
        margin-top: 0.35vw;
      }

      .signin-text {
        font-family: 'Futura PT Demi';
        font-size: 2.35vw;
      }

      .signin-label-text {
        font-family: 'Futura PT Medium';
        font-size: 1.25vw;
        color: #b9b9b9;
      }

      .signin-form-parent {
        .form-inner-div {
          &:first-child {
            margin-top: 35px;
          }

          &:not(:first-child) {
            margin-top: 20px;
          }

          .form-label {
            font-family: 'Futura PT Medium';
            font-size: 16px;
            color: #73707b;
          }

          &:not(.form-otp-input) {
            .form-control {
              border: 1px solid #e0e0e0;
              border-radius: 10px;
              font-size: 0.94vw;
              padding: 0.5vw 0.75vw;
              height: 5.5vh;

              &::placeholder {
                font-family: 'Futura PT Medium';
                color: #b9b9b9;
              }

              &:focus {
                box-shadow: none;
              }

              &.ng-dirty.ng-invalid {
                &::placeholder {
                  color: #d32f2f;
                }

                border: 1px solid rgba(255, 71, 71, 0.34);
                color: #d32f2f;
              }
            }
          }

          .show-hide-password {
            position: absolute;
            top: 1.1vh;
            right: 1.45vw;
          }

          .help-block {
            font-family: 'Futura PT Medium';

            &.text-success {
              color: #00c853 !important;
            }
          }
        }

        .reset-pass-text {
          height: inherit;
          padding-right: 1.5vw;

          a {
            color: #7f56d9;
            font-family: 'Futura PT Medium';
            font-size: 0.8vw;
            text-decoration: none;
          }
        }

        .auth-btn {
          margin-top: 3rem;
          height: 5.5vh;
          background: #ffffff;
          border: 1px solid #7f56d9;
          border-radius: 10px;

          &:hover {
            background: #7f56d9;
            color: #fff;
          }
        }

        .common-btn {
          width: 100%;
          color: #000;
          font-family: 'Futura PT Medium';
          font-size: 0.94vw;
          padding: 0.7vw;
        }

        .cancel-btn {
          background: #000850 0% 0% no-repeat padding-box;
          margin-top: 1.25vw;
        }
      }

      .privacy-policy-text {
        font-family: 'Helvetica-Light';
        font-size: 0.94vw;
        margin-top: 4.5vw;

        a {
          color: #000d7b;
          text-decoration: none;
        }
      }
    }

    .auth-left-section {
      background: transparent;
      // padding: 96px;
      padding: 4.5vw 0 4.5vw 4.5vw;
      height: 100vh;

      .main-logo {
        height: 3vw;
        position: relative;
      }

      .welcome-heading {
        font-family: 'Futura PT Demi';
        font-size: 3.75vw;
        color: #fff;
        position: relative;
        width: 34vw;
      }

      .label-heading {
        font-family: 'Futura PT Demi';
        font-size: 2.35vw;
        color: #fff;
        position: relative;
      }

      .forgot-password-label {
        font-family: 'Futura PT Demi';
        font-size: 32px;
        color: #fff;
        position: absolute;
        line-height: 52px;

        a {
          color: #fff;
        }
      }

      .label-bonus-tip {
        color: #fff;
        position: relative;
        font-family: 'Futura PT Medium';
        font-size: 24px;
      }

      .auth-left-text {
        font-size: 1.46vw;
        font-family: 'Futura PT Demi';
        letter-spacing: 1.25px;
        color: #1da1f1;
        line-height: 1.7;
      }

      .express-img-div {
        width: 12.4vh;
        height: 12.4vh;
        background: #fff;
        border-radius: 50%;
        top: 25%;
        left: 45%;
        z-index: 1;
        position: absolute;

        .img-fluid {
          width: 5.15vh;
        }
      }

      .circles-parent {
        position: absolute;
        inset: 0;
      }

      .solid-circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }

      .solid-circle-grey {
        background: rgba(255, 255, 255, 0.3);
      }

      .common-circle-grey {
        border: 20px solid rgba(255, 255, 255, 0.3);
      }

      .circle1 {
        height: 16.5vw;
        width: 16.5vw;
        top: 32%;
        left: 48%;
        position: absolute;
        background: #594ec7;
        border: 1px solid #594ec7;
        animation-delay: 9s;
      }

      .circle2 {
        height: 43vw;
        width: 43vw;
        top: 31%;
        left: 48%;
        position: absolute;
        background: #4f45c5;
        border: 1px solid #4f45c5;
        animation-delay: 5s;
      }

      .circle3 {
        height: 88vw;
        width: 88vw;
        top: 32%;
        left: 47%;
        position: absolute;
        background: #7056f4;
        border: 1px solid #7056f4;
        animation-delay: 1s;
      }

      .common-circle {
        border-radius: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
      }

      .common-circle-1 {
        animation: growAndFade 5s infinite ease-out;
      }

      @keyframes growAndFade {
        0% {
          opacity: 0;
        }

        30% {
          opacity: 0.8;
        }

        100% {
          opacity: 0;
        }
      }
    }
  }
}