.modal-content {
  background-color: var(--color-white) !important;
  border-radius: 8px !important;

  .es-modal {
    .modal-header-flex {
      display: flex;
      justify-content: space-between;
      height: 4.5rem;

      .es-btn-close-container {
        .es-btn-close {
          width: 32px;
          height: 32px;
          position: absolute;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }

          &:before,
          &:after {
            position: absolute;
            left: 10px;
            content: ' ';
            height: 17px;
            width: 2px;
            background-color: var(--color-dark);
          }

          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .modal-header-text {
      color: var(--color-dark);
      font-size: var(--modal-header-text-size);
      font-family: 'SF Pro Display Bold';
    }

    .modal-sub-header-text {
      margin-top: 5px;
      color: var(--color-dark);
      opacity: 0.5;
      font-style: normal;
      font-weight: 400;
      font-size: var(--sidebar-text-size);
      line-height: 20px;
    }

    .modal-sub-header-emil-text {
      color: var(--color-dark);
      font-style: normal;
      font-weight: 400;
      font-size: var(--sidebar-text-size);
      line-height: 20px;
    }

    .es-modal-body {
      margin-top: 16px;

      .es-modal-form {
        .es-modal-form-label {
          color: var(--color-dark);
          font-size: 16px;
          font-weight: 600;
        }

        .es-input {
          background-color: transparent;
          color: var(--color-dark);
          border-radius: 8px;
          border: 1px solid var(--color-input-border);

          &::placeholder {
            color: var(--color-dark);
            opacity: 0.5;
          }
        }

        .border-purple {
          border: 1px solid var(--color-input-border-hover) !important;
        }

        input:-internal-autofill-selected {
          appearance: menulist-button;
          background-image: none !important;
          background-color: none !important;
        }

        .ng-dropdown-panel {
          margin-top: 10px;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          border: none;
          background: var(--color-white);

          .ng-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px 10px 10px;
            background: var(--color-white);
            color: var(--color-dark);
          }
        }

        option {
          color: var(--color-dark) !important;
        }
      }
    }

    .modal-header-text {
      color: var(--color-dark);
      font-size: 18px;
      letter-spacing: 0;
    }

    .modal-sub-header-text {
      margin-top: 5px;
      color: var(--color-dark);
      opacity: 80%;
      font-style: normal;
      font-weight: 400;
      font-size: var(--sidebar-text-size);
      line-height: 20px;
    }

    .modal-sub-header-emil-text {
      color: var(--color-dark);
      font-style: normal;
      font-weight: 400;
      font-size: var(--sidebar-text-size);
      line-height: 20px;
    }

    .es-modal-body {
      margin-top: 16px;

      .es-modal-form {
        .es-modal-form-label {
          color: var(--color-dark);
          font-size: 16px;
          font-weight: 600;
        }

        .es-input {
          background-color: transparent;
          color: var(--color-dark);
          border-radius: 8px;
          border: 1px solid var(--color-input-border);

          &::placeholder {
            color: var(--color-dark);
          }
        }

        .border-purple {
          border: 1px solid var(--color-input-border) !important;
        }

        .border-error {
          border: 1px solid rgb(225, 112, 112) !important;
        }

        .text-danger {
          color: rgb(225, 112, 112) !important;
        }

        input:-internal-autofill-selected {
          appearance: menulist-button;
          background-image: none !important;
          background-color: none !important;
          color: fieldtext !important;
        }

        .ng-dropdown-panel {
          margin-top: 10px;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          border: none;
          background: var(--color-white);
          padding: 6px;

          .ng-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px 10px 10px;
            background: var(--color-white);
            color: var(--color-dark);
            border-radius: 8px;
            margin-bottom: 4px;
          }

          .ng-option-marked,
          .ng-option-selected {
            position: relative;
            background-color: unset;

            &::before {
              content: '';
              position: absolute;
              inset: 0;
              background-color: var(--color-primary) !important;
              opacity: 10%;
              z-index: 3;
            }
          }
        }

        option {
          color: var(--color-dark) !important;
        }

        .imsi-input .ng-dropdown-panel {
          margin-top: 10px;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          border: none;
          background: var(--color-white);

          .ng-option {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: 10px 10px 10px 10px;
            background: var(--color-white);
            color: var(--color-dark);
          }

          .ng-option-disabled {
            flex-direction: row;
          }
        }
      }

      .success-error-heading {
        color: var(--color-dark);
      }

      .success-error-text {
        color: var(--color-dark);
      }

      /* upload inventory section - start */
      .file-upload-body {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 8px;
        margin-bottom: 10px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          border: 1px solid var(--color-primary);
          border-radius: 7px;
          opacity: 30%;
        }

        .file-icon {
          display: flex;
          align-items: center;

          .file-icon-border {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 32px;
            margin-right: 8px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              inset: 0;
              border: 1px solid var(--color-primary);
              border-radius: 4px;
              opacity: 30%;
            }
          }
        }
      }

      .file-download-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        padding: 8px;
        margin-bottom: 10px;
        color: var(--color-dark);
        position: relative;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          border: 1px solid var(--color-primary);
          border-radius: 7px;
          opacity: 30%;
        }

        .file-icon-name {
          display: flex;
          align-items: center;
          color: var(--color-dark);
          opacity: 0.5;
          font-size: 0.75rem;

          .file-icon-border {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 32px;
            margin-right: 8px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              inset: 0;
              border: 1px solid var(--color-primary);
              border-radius: 4px;
              opacity: 30%;
            }
          }

          .file-icon-fill {
            fill: var(--color-dark);
          }
        }

        path {
          &:not(.color-stroke) {
            fill: var(--color-button-primary);
          }

          &.color-stroke {
            stroke: var(--color-button-primary);
          }
        }
      }

      /* upload inventory section - end */
    }

    // Plan dialog
    .plan-dialog-bodyscroll {
      height: 63vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 8px;
    }
  }
}

.select-bg {
  background-color: var(--dark-background) !important;
  color: var(--dark-text-white-v2) !important;
}

.has-backdrop {
  min-height: 100vh;
  // height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
}

.btn-primary {
  background: var(--color-button-primary) !important;
}

.add-edit-icon {
  svg {
    transform: scale(1);
  }
}

.relative {
  position: relative;
}

.subsInfo {
  --bs-modal-width: 530px !important;
}

.border-right-valid-color {
  border-right-color: rgba(99, 101, 239, 0.48) !important;
}

.border-right-error-color {
  border-right-color: rgb(225, 112, 112) !important;
}

.user-mobile-input .user-mobile-btn {
  width: 45% !important;

  .btn{
    font-weight: 400 !important;
  }
}
