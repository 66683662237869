// Reports /////////////////////////////////////////

.dashboard-head-flex-container {
  display: flex;

  .dashboard-head-flex {
    width: 200px;
    margin-right: 20px;
  }

  .dashboard-head-flex-date-range {
    width: 310px;
  }
}

// admin breadcrumb

.admin-breadcrumb {
  margin-bottom: 10px;

  .breadcrumb {
    .breadcrumb-item {
      padding-left: 0;
      color: var(--color-dark);
      font-family: 'SF Pro Display';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;

      @media screen and (max-width: 1900px) {
        font-size: 16px;
      }

      &::before {
        content: '';
        display: none;
      }

      .breadcrumb-arrow-container {
        margin-left: 10px;
        margin-right: 10px;

        svg {
          path {
            fill: var(--color-dark);
          }
        }
      }
    }
  }

  .active-bredcrumb {
    color: var(--color-primary);
  }
}

// input password ///

input::-webkit-credentials-auto-fill-button {
  display: none !important;
}

.form-control:focus {
  color: var(--color-dark);
}

// input:-webkit-autofill {
//   -webkit-text-security: disc !important;
// }

.accordion-button {
  -webkit-appearance: none !important;
}

// white space /////////

.white-s-no-wrap {
  white-space: nowrap !important;
}

// accordian ///////////////

.accordion {
  --bs-accordion-bg: none !important;
}

// tabel////

.table {
  --bs-table-bg: transparent !important;
  --bs-table-color: unset !important;
}