@font-face {
  font-family: 'Helvetica';
  src: url('/assets/fonts/helvetica-font/HelveticaNeue.ttf');
}

@font-face {
  font-family: 'Helvetica-Light';
  src: url('/assets/fonts/helvetica-font/HelveticaNeueLt.ttf');
}

@font-face {
  font-family: 'Helvetica-Medium';
  src: url('/assets/fonts/helvetica-font/HelveticaNeueMedium.ttf');
}

@font-face {
  font-family: 'Helvetica-Thin';
  src: url('/assets/fonts/helvetica-font/HelveticaNeueThin.ttf');
}

@font-face {
  font-family: 'Futura PT Book';
  font-style: normal;
  font-weight: normal;
  src:
    local('Futura PT Book'),
    url('/assets/fonts/futura-pt-font/FuturaCyrillicBook.woff') format('woff');
}

@font-face {
  font-family: 'Futura PT Light';
  font-style: normal;
  font-weight: normal;
  src:
    local('Futura PT Light'),
    url('/assets/fonts/futura-pt-font/FuturaCyrillicLight.woff') format('woff');
}

@font-face {
  font-family: 'Futura PT Medium';
  font-style: normal;
  font-weight: normal;
  src:
    local('Futura PT Medium'),
    url('/assets/fonts/futura-pt-font/FuturaCyrillicMedium.woff') format('woff');
}

@font-face {
  font-family: 'Futura PT Demi';
  font-style: normal;
  font-weight: normal;
  src:
    local('Futura PT Demi'),
    url('/assets/fonts/futura-pt-font/FuturaCyrillicDemi.woff') format('woff');
}

@font-face {
  font-family: 'Futura PT Heavy';
  font-style: normal;
  font-weight: normal;
  src:
    local('Futura PT Heavy'),
    url('/assets/fonts/futura-pt-font/FuturaCyrillicHeavy.woff') format('woff');
}

@font-face {
  font-family: 'Futura PT Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Futura PT Bold'),
    url('/assets/fonts/futura-pt-font/FuturaCyrillicBold.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: normal;
  src: local('SF Pro Display'), url('/assets/fonts/sf-pro-display-font/SFPRODISPLAYREGULAR.OTF');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  font-style: normal;
  font-weight: normal;
  src: local('SF Pro Display Bold'), url('/assets/fonts/sf-pro-display-font/SFPRODISPLAYBOLD.OTF');
}

@font-face {
  font-family: 'SF Pro Display Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF Pro Display Medium'),
    url('/assets/fonts/sf-pro-display-font/SFPRODISPLAYMEDIUM.OTF');
}

@font-face {
  font-family: 'SF Pro Display Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('SF Pro Display Italic'),
    url('/assets/fonts/sf-pro-display-font/SFPRODISPLAYULTRALIGHTITALIC.OTF') format('OTF');
}

@font-face {
  font-family: 'SF Pro Display Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF Pro Display Regular'), url('/assets/fonts/sf-pro-display-font/SFPRODISPLAYREGULAR.OTF') format('OTF');
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter-font/Inter-Regular.ttf");
}

@font-face {
  font-family: 'Inter Bold';
  src: url('/assets/fonts/inter-font/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter Light';
  src: url('/assets/fonts/inter-font/Inter-Light.ttf');
}

@font-face {
  font-family: 'TT Firs Neue Trial ExtraBold';
  src: url('/assets/fonts/tt-firs-neue-trl-cufonfonts-webfont/TT Firs Neue Trial ExtraBold.woff');
}

@font-face {
  font-family: 'TT Firs Neue Trial Bold';
  src: url('/assets/fonts/tt-firs-neue-trl-cufonfonts-webfont/TT Firs Neue Trial Bold.woff');
}

@font-face {
  font-family: 'TT Firs Neue Trial Medium';
  src: url('/assets/fonts/tt-firs-neue-trl-cufonfonts-webfont/TT Firs Neue Trial Medium.woff');
}

@font-face {
  font-family: 'TT Firs Neue Trial Regular';
  src: url('/assets/fonts/tt-firs-neue-trl-cufonfonts-webfont/TT Firs Neue Trial Regular.woff');
}